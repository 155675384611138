<template>
  <div class="location-tree-modes">
    <label
      v-for="mode in modes"
      :key="mode.value"
      class="location-tree-modes__mode"
      :class="{
        'location-tree-modes__mode--checked': isChecked(mode.value),
      }"
    >
      <input
        type="radio"
        :value="mode.value"
        @change="$emit('input', $event.target.value)"
        :checked="isChecked(mode.value)"
      />
      {{ mode.label }}
    </label>
  </div>
</template>

<script>
import { DEFAULT_TREE_MODES } from '@/utils/constants/location-tree';

export default {
  props: {
    value: {
      type: String,
    },
    modes: {
      type: Array,
      default: () => DEFAULT_TREE_MODES,
    },
  },
  emits: ['input'],

  methods: {
    isChecked(modeValue) {
      return this.value === modeValue;
    }
  },
};
</script>

<style scoped>
.location-tree-modes {
  @apply flex;
}

.location-tree-modes__mode {
  @apply flex font-frank font-normal text-2sm mr-4;

  &:last-child {
    @apply mr-0;
  }
}

.location-tree-modes__mode--checked {
  @apply text-active-500;
}
</style>
