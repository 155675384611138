<template>
  <div class="flex flex-col h-full">
    <div class="font-frank font-bold text-sm text-black mb-4">notes</div>

    <Chat ref="chat" :id="chatId" :messages-type="MESSAGE_TYPE_NOTE" reverse-order/>
  </div>
</template>

<script>
import Chat from "@/components/chat/Chat";
import {MESSAGE_TYPE_NOTE} from "@/utils/constants/chat";

export default {
  components: {Chat},

  props: {
    chatId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      MESSAGE_TYPE_NOTE,
    };
  },
}
</script>
