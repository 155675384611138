<template>
  <div class="relative">
    <div
        ref="collapseContainer"
        class="text-collapse"
        :style="{ '-webkit-line-clamp': isExpanded ? null : maxLines }"
    >
      {{ text }}
    </div>
    <span
        v-if="expandable"
        @click="isExpanded = !isExpanded"
        class="flex justify-end font-frank font-medium text-blue-500 cursor-pointer"
    >
      show {{ isExpanded ? 'less' : 'more' }}
      <ChevronDownIcon
          class="w-5"
          :style="{ transform: isExpanded ? 'rotate(180deg)' : null }"
      />
    </span>
  </div>
</template>

<script>
import ChevronDownIcon from "@/components/ui/icons/ChevronDownIcon";

export default {
  name: 'TextCollapse',

  components: {ChevronDownIcon},

  props: {
    text: {
      type: String,
      required: true,
    },

    maxLines: {
      type: Number,
      default: 3,
    },
  },

  data() {
    return {
      isExpanded: false,
      expandable: false,
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.expandable = this.$refs.collapseContainer.scrollHeight > this.$refs.collapseContainer.clientHeight;
    });
  },
};
</script>

<style scoped>
.text-collapse {
  @apply relative overflow-hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
</style>
