<template>
<div class="h-full">
  <div class="columns-wrapper" v-if="unit">
    <div class="main-info bg-gray-100 p-5">
      <FinalForm ref="form" :submit="handleMainInfoSubmit" :initial-values="initialValues" class="h-full">
        <template v-slot="formProps">
          <form @submit="formProps.handleSubmit" class="flex flex-col h-full overflow-auto">
            <div class="flex items-center justify-between mb-4">
              <span class="font-frank font-bold text-sm">unit main info</span>
              <EditIconsBlock
                  v-if="hasAnyEditPermission"
                  :edit-mode="editMode"
                  @startEdit="editMode = true"
                  @cancel="cancelEdit"
                  @save="formProps.handleSubmit"
              />
            </div>

            <div class="grid grid-cols-2 gap-4 flex-grow overflow-auto">
              <div class="cell">
                <div class="title">unit number</div>
                <div class="content">{{ unit.unitNumber }}</div>
              </div>

              <div class="cell">
                <div class="title">building</div>
                <div class="content">{{ unit.building.name }}</div>
              </div>

              <div class="cell" :class="{'col-span-2': editUnitType}">
                <div class="title">unit type</div>
                <div class="content">
                  <FinalField name="unitType" :validate="required">
                    <template v-slot="unitTypeProps">
                      <div>
                        <template v-if="!editUnitType">
                          <template v-if="unitTypeProps.value">
                            <router-link
                                v-if="hasCbrPermission('UTV')"
                                :to="{name: 'cbr.unittypes.details', params: {typeId: unitTypeProps.value.id}}"
                                class="text-link"
                            >
                              {{unitTypeProps.value.name}}
                            </router-link>
                            <template v-else>{{unitTypeProps.value.name}}</template>
                          </template>
                          <template v-else>-</template>
                        </template>
                        <Dropdown
                            v-else
                            :value="unitTypeProps.value"
                            @input="val => unitTypeProps.change(val)"
                            :data-provider="$cbrDataProvider"
                            resource="unitTypes"
                            :searchable="false"
                            label="name"
                            track-by="id"
                            lazy-load
                            object-mode
                            no-clear
                        >
                          <template v-slot:tags="{controlText, openDropdown}">
                            <div class="flex items-center justify-between w-full cursor-pointer">
                              <div @click.stop="openDropdown">{{controlText}}</div>
                              <template v-if="unitTypeProps.value">
                                <router-link
                                    v-if="hasCbrPermission('UTV')"
                                    class="text-link"
                                    :to="{name: 'cbr.unittypes.details', params: {typeId: unitTypeProps.value.id}}"
                                >
                                  view details
                                </router-link>
                              </template>
                            </div>
                          </template>
                          <template v-slot:item="{option, selected}">
                            <div class="grid grid-cols-3 gap-3 p-3 hover:bg-blue-50" :class="selected && 'bg-blue-100'">
                              <div class="flex items-center justify-center">
                                <ManagedImage class="h-auto" :id="option.floorPlan.image.id" thumb/>
                              </div>
                              <div class="col-span-2">
                                <div class="text-sm text-black mb-1">{{ option.name }}</div>
                                <div class="text-xs text-gray-500 whitespace-normal">{{ option.floorPlan.name }} floor plan</div>
                              </div>
                            </div>
                          </template>
                        </Dropdown>
                        <span v-if="unitTypeProps.meta.error && unitTypeProps.meta.touched" class="form-hint form-error">
                          {{ unitTypeProps.meta.error }}
                        </span>
                      </div>
                    </template>
                  </FinalField>
                </div>
              </div>

              <div v-if="!editUnitType" class="cell">
                <div class="title">floor</div>
                <div class="content">{{ unit.floor }}</div>
              </div>

              <div v-if="floorPlan" class="cell" :class="editUnitType ? 'row-span-2' : 'row-span-3'">
                <div class="title">floor plan</div>
                <div class="content">
                  <router-link
                      v-if="hasCbrPermission('FPV')"
                      :to="{name: 'cbr.floorplans.details', params: {planId: floorPlan.id}}"
                      class="text-link"
                  >
                    {{ floorPlan.name }}
                  </router-link>
                  <template v-else>{{ floorPlan.name }}</template>
                  <ManagedImage class="w-full h-auto mt-2" :id="floorPlan.image.id" thumb />
                </div>
              </div>

              <div v-if="editUnitType" class="cell">
                <div class="title">floor</div>
                <div class="content">{{ unit.floor }}</div>
              </div>

              <div class="cell" :class="{'col-span-2': editParkingSpaces}">
                <div class="title">parking spaces</div>
                <div class="content">
                  <SelectInput
                      name="parkingSpaces"
                      :options="parkingSpaceOptions"
                      option-key="id"
                      option-label="name"
                      :edit-mode="editParkingSpaces"
                      object-mode
                      multiple
                  />
                </div>
              </div>

              <div class="cell" :class="{'col-span-2': editStatus}">
                <div class="title">unit status</div>
                <div class="content">
                  <SelectInput :edit-mode="editStatus" :options="unitStatuses" name="unitStatus" :validate="required"/>
                </div>
              </div>

              <div class="cell" :class="{'col-span-2': editReadyToShow}">
                <div class="title">ready to show</div>
                <div class="content">
                  <div v-if="!editReadyToShow" class="flex items-baseline">
                    <template v-if="formProps.values.readyToShow">
                      <CheckmarkIcon class="w-3 h-3 text-green-600 mr-2"/> yes
                    </template>
                    <template v-else>
                      <CloseIcon class="w-3 h-3 text-red-700 mr-2"/> no
                    </template>
                  </div>
                  <ToggleField
                      v-else
                      size="small"
                      name="readyToShow"
                      :inputLabel="formProps.values.readyToShow ? 'yes' : 'no'"
                  />
                </div>
              </div>

              <div v-if="editMode" class="border-t col-span-2 text-sm font-frank font-bold pt-4">specs</div>
              <hr v-else class="col-span-2"/>

              <div class="cell">
                <div class="title">square footage</div>
                <div class="content">{{ unit.squareFootage }} sq ft</div>
              </div>

              <div class="cell" v-if="unit.unitType">
                <div class="title">layout</div>
                <div class="content">{{ unit.unitType.bedroomNum }} bed, {{ unit.unitType.bathroomNum }} bath</div>
              </div>

              <hr v-if="!editMode" class="col-span-2"/>

              <div class="cell col-span-2">
                <div class="title">amenities</div>
                <div class="content">
                  <div class="mb-2">
                    {{ Object.entries(unit.amenities).map(([key, value]) => (`${key} (${value})`)).join(', ') }}
                  </div>
                  <div class="">
                    Today's amenities amount: {{ unit.amenitiesTotalPrice ? formatCurrency(unit.amenitiesTotalPrice) : '0' }}
                  </div>
                </div>
              </div>

              <div class="cell col-span-2">
                <div class="title">devices</div>
                <div class="content">
                  {{ Object.entries(unit.devices).map(([key, value]) => (`${key} (${value})`)).join(', ') }}
                </div>
              </div>

              <div class="cell col-span-2">
                <div class="title">rentable items</div>
                <div class="content">
                  {{ Object.entries(unit.rentableItems).map(([key, value]) => (`${key} (${value})`)).join(', ') }}
                </div>
              </div>

            </div>
          </form>
        </template>
      </FinalForm>
    </div>

    <div class="tabs">

      <Tabs ref="tabs">
        <Tab title="lease">
          <LeaseInformation :unit-id="unit.id"/>
        </Tab>
        <Tab title="service requests" v-if="hasSreqPermission('SRV')">
          <template v-slot:default="{isActive}">
            <ServiceRequests
                :location-id="unit.id"
                :location-name="unit.unitNumber"
                :is-visible="isActive"
            />
          </template>
        </Tab>
        <Tab title="lease history">
          <LeaseHistory/>
        </Tab>
        <Tab title="notes">
          <Notes ref="notesTab" :chat-id="unit.chatId"/>
        </Tab>
      </Tabs>
    </div>
  </div>
  <loader :loading="loading" backdrop/>
</div>
</template>

<script>
import ModalNavigation from "@/mixins/ModalNavigation";
import NotifyMixin from "@/mixins/NotifyMixin";
import Loader from "@/components/ui/Loader";
import CloseIcon from "@/components/ui/icons/CloseIcon";
import ManagedImage from "@/components/ui/ManagedImage";
import Tabs from "@/components/ui/tabs/Tabs";
import Tab from "@/components/ui/tabs/Tab";
import LeaseInformation from "@/components/cbr/living_unit/details/LeaseInformation";
import ServiceRequests from "@/components/cbr/locations/ServiceRequests";
import LeaseHistory from "@/components/cbr/living_unit/details/LeaseHistory";
import Notes from "@/components/cbr/living_unit/details/Notes";
import SelectInput from "@/components/form/SelectInput";
import {FinalForm, FinalField} from "vue-final-form";
import ToggleField from "@/components/form/ToggleField";
import ValidatorMixin from "@/components/form/ValidatorMixin";
import CheckmarkIcon from "@/components/ui/icons/CheckmarkIcon";
import SreqMixin from "@/mixins/SreqMixin";
import Dropdown from "@/components/ui/Dropdown";
import {mapGetters} from "vuex";
import ActionsMixin from '@/mixins/ActionsMixin';
import EditIconsBlock from "@/components/ui/EditIconsBlock";
import ConfirmationMixin from "@/mixins/ConfirmationMixin";
import {formatCurrency} from "@/utils/String";

export default {
  components: {
    EditIconsBlock,
    FinalField,
    Dropdown,
    CheckmarkIcon,
    ToggleField,
    FinalForm,
    SelectInput,
    Notes,
    LeaseHistory,
    ServiceRequests,
    LeaseInformation,
    Tab,
    Tabs,
    ManagedImage,
    CloseIcon,
    Loader,
  },

  mixins: [SreqMixin, ModalNavigation, NotifyMixin, ValidatorMixin, ActionsMixin, ConfirmationMixin],

  data() {
    return {
      editMode: false,
      loading: false,
      unit: null,
      floorPlan: null,
      parkingSpaceOptions: [],
      unitStatuses: [
        {key: 'RENTED', value: 'Rented'},
        {key: 'VACANT_NOT_LEASED', value: 'Vacant - not leased'},
        {key: 'VACANT_LEASED', value: 'Vacant - leased'},
        {key: 'NTV_AVAILABLE', value: 'NTV - available'},
        {key: 'NTV_LEASED', value: 'NTV - leased'},
        {key: 'MODEL', value: 'Model'},
        {key: 'DOWN', value: 'Down'},
        {key: 'REHAB', value: 'Rehab'},
        {key: 'SHOP', value: 'Shop'},
      ],
      defaultActiveTabIndex: 0,
    };
  },

  computed: {
    ...mapGetters({
      hasCbrPermission: 'cbr/hasPermission',
    }),

    formValues() {
      return this.$refs.form?.formState?.values || {};
    },
    initialValues() {
      return {
        unitType: this.unit?.unitType || null,
        parkingSpaces: this.unit?.parkingSpaces || [],
        unitStatus: this.unit?.status,
        readyToShow: this.unit?.readyToShow,
      };
    },

    editUnitType() {
      return this.editMode && this.hasCbrPermission('UDUUT');
    },

    editParkingSpaces() {
      return this.editMode && this.hasCbrPermission('UDUP');
    },

    editStatus() {
      return this.editMode && this.hasCbrPermission('UDUS');
    },

    editReadyToShow() {
      return this.editMode && this.hasCbrPermission('UDURS');
    },

    hasAnyEditPermission() {
      return this.hasCbrPermission('UDUUT') ||
          this.hasCbrPermission('UDUP') ||
          this.hasCbrPermission('UDUS') ||
          this.hasCbrPermission('UDURS');
    },
  },

  methods: {
    formatCurrency,

    cancelEdit() {
      this.editMode = false;
      setTimeout(this.$refs.form.finalForm.restart);
    },

    handleMainInfoSubmit(values) {
      this.loading = true;
      this.$cbrDataProvider.update('livingUnits', {
        id: this.unit.id,
        data: {
          readyToShow: values.readyToShow,
          status: values.unitStatus,
          unitTypeId: values.unitType.id,
          parkingSpaces: values.parkingSpaces.map(({id}) => ({id})),
        }
      }).then((unit) => {
        this.unit = unit;
        this.editMode = false;
      }).catch(error => {
        this.notifyError(error.message);
      }).finally(() => {
        this.loading = false;
      });
    },

    async handleRemoveFromUnits() {
      this.loading = true;

      try {
        const location = await this.$cbrDataProvider.getOne('locations', {
          id: this.$route.params.unitId,
        });

        await this.$cbrDataProvider.update('locations', {
          id: this.$route.params.unitId,
          data: {
            l2Data: {
              ...location.l2Data,
              businessPurpose: null,
            },
          },
        });

        this.notifySuccess('location has been unassigned from units');
        this.close();
      } catch (error) {
        this.notifyError(error.message);
      } finally {
        this.loading = false;
      }
    },
  },

  mounted() {
    this.loading = true;

    Promise.all([
      this.$cbrDataProvider.getOne('livingUnits', {id: this.$route.params.unitId}).then(response => {
        this.unit = response;
        this.floorPlan = this.unit.unitType?.floorPlan;
        this.parkingSpaceOptions = this.unit.parkingSpaces; // To display them before select options loaded
        this.setActiveModalTitle(`unit ${this.unit.unitNumber}`);
        this.setActiveModalBreadcrumbName(`unit ${this.unit.unitNumber}`);
      }).then(() => {
        this.$refs.tabs.openTab(this.defaultActiveTabIndex);
      }),
    ])
        .then(() => {
          this.$watch(
              () => this.formValues.unitType,
              (val) => {
                this.floorPlan = val?.floorPlan;
              }
          );
        })
        .catch(error => this.notifyError(error.message))
        .finally(() => this.loading = false);

    const unwatch = this.$watch('editMode', (editMode) => {
      if (editMode) {
        this.$cbrDataProvider.availableParkingSpaces('livingUnits', {unitId: this.$route.params.unitId})
            .then((response) => {
              this.parkingSpaceOptions = response;
              unwatch();
            })
            .catch(error => {
              this.notifyError(error.message);
            });
      }
    })

    if (this.hasCbrPermission('LU')) {
      this.addConfirmAction(
        {
          id: 'remove-from-units',
          title: 'remove from units',
          showConfirmation: true,
          confirmationType: 'warning',
          confirmationMessage: 'are you sure you want to unassign this location from units?',
          confirmBtnText: 'yes, unassign',
          cancelBtnText: 'no, go back',
        },
        this.handleRemoveFromUnits,
      );
    }
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.meta.product === 'sreq') {
        vm.defaultActiveTabIndex = 1;
      }
    });
  },

  async beforeRouteLeave() {
    if (this.$refs.notesTab.$refs.chat.newMessageText && !(await this.requestConfirmation({
      confirmationType: 'warning',
      confirmationMessage: 'are you sure you want to leave without saving changes?',
      confirmBtnText: 'yes, leave without saving',
      cancelBtnText: 'no, go back',
    }))) {
      return false;
    }
  },
}
</script>

<style scoped>
.columns-wrapper {
  @apply grid grid-rows-1 col-gap-6 w-full h-full;
  grid-template-columns: 1fr 2fr;
}

.columns-wrapper:deep(.main-info) {
  @apply text-black;

  .multiselect__tags {
    @apply h-8;
    padding: .5625rem .75rem .5rem;
  }

  .multiselect__input, .multiselect__placeholder, .multiselect__single, .multiselect__option {
    @apply text-xs;
  }

  .form-col {
    @apply mr-0;
  }
}

.cell {
  .title {
    @apply text-xs font-semibold mb-2;
  }

  .content {
    @apply text-2sm;
  }
}

.columns-wrapper:deep(.tabs) {
  > div {
    @apply flex flex-col h-full overflow-auto;

    > :not(.tabs-wrapper) {
      @apply flex-grow overflow-auto;
    }
  }

  .tabs-wrapper {
    @apply mx-0 mb-6 border-b-2 border-gray-200;
  }

  .tab-head {
    @apply font-frank font-medium text-sm m-0;
    color: #87C2EB;

    &:not(:last-child) {
      @apply mr-8;
    }

    &.active {
      @apply border-b-2 text-blue-500;
      margin-bottom: -2px;
    }
  }
}

.text-link {
  @apply text-2sm;
}
</style>
