<template>
  <div class="cursor-pointer border p-4 mb-2 hover:border-blue-500 text-2sm" @click="goToDetails">
    <div class="flex items-center justify-between mb-2">
      <div class="font-frank font-bold">{{ request.data.title }}</div>
      <community-date-time-display :input="request.createdAt" format="MM/DD/YYYY"/>
    </div>
    <div class="flex items-center justify-between text-gray-500 lowercase">
      <div>{{ getLocationName(request.data.location) }}</div>
      <div>{{ request.metadata.currentState.name }}</div>
    </div>
  </div>
</template>

<script>
import CommunityDateTimeDisplay from "@/components/ui/CommunityDateTimeDisplay";

const getTreeItemWithId = (location, targetId) => {
  if (location.id === targetId) {
    return location;
  } else if (location.locations?.[0]) {
    return getTreeItemWithId(location.locations[0], targetId);
  }
  return null;
};

export default {
  name: "ServiceRequest",

  components: {CommunityDateTimeDisplay},

  props: {
    request: {
      type: Object,
      required: true,
    },

    sourceUnitId: {
      type: String,
      required: true,
    },
  },

  methods: {
    getLocationName(location) {
      const unit = getTreeItemWithId(location, this.sourceUnitId);
      return unit.locations?.[0] ? unit.locations[0].name : unit.name;
    },

    goToDetails() {
      this.$router.push({name: 'sreq.details', params: {requestId: this.request.id}, query: {locationId: this.sourceUnitId}});
    },
  },
}
</script>
