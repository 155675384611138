<template>
  <TextInput
    :placeholder="placeholder"
    :value="value"
    @input="handleInput"
    @clear="this.$emit('input',  '');"
    clearable
  >
    <template #leading-icon>
      <Icon name="search2" class="w-4 h-4 text-graphite-800"/>
    </template>
  </TextInput>
</template>

<script>
import { debounce } from 'lodash-es';
import TextInput from '@/components/ui/TextInput';
import Icon from '@/components/ui/Icon';

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'search',
    },
  },

  components: {
    TextInput,
    Icon,
  },

  emits: ['input'],

  methods: {
    handleInput: debounce(function (value) {
      this.$emit('input', value);
    }, 500),
  },
};
</script>
