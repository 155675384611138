<template>
  <div class="text-black">
    <template v-if="lease !== undefined">
      <div class="flex items-baseline justify-between mb-6">
        <div class="text-sm font-frank font-bold">lease information</div>
        <router-link v-if="lease" to="#" class="text-link">view details</router-link>
      </div>
      <div class="grid grid-cols-3 gap-4 mb-8">
        <template v-if="lease !== null">
          <div class="cell">
            <div class="title">lease status</div>
            <div class="text-2sm">
              {{ $t(`cbr.lease.statuses.${lease.status}`) === `cbr.lease.statuses.${lease.status}` ? lease.status : $t(`cbr.lease.statuses.${lease.status}`) }}
            </div>
          </div>

          <div class="cell">
            <div class="title">start date</div>
            <div class="text-2sm">
              <community-date-time-display :input="lease.startDate" format="MM/DD/YYYY" keep-local-time/>
            </div>
          </div>

          <div class="cell">
            <div class="title">expiration date</div>
            <div class="text-2sm">
              <community-date-time-display :input="lease.expirationDate" format="MM/DD/YYYY" keep-local-time/>
            </div>
          </div>

          <div v-if="lease.moveInDate" class="cell">
            <div class="title">move-in</div>
            <div class="text-2sm">
              <community-date-time-display :input="lease.moveInDate" format="MM/DD/YYYY" keep-local-time/>
            </div>
          </div>

          <div v-if="lease.renewal" class="cell">
            <div class="title">renewal</div>
            <div class="text-2sm">
              <div v-if="lease.renewal === 'accepted'" class="flex items-center">
                <checkmark-icon class="w-4 h-4 text-leaf-600"/>
                <span class="ml-1">Accepted</span>
              </div>
              <div v-else-if="lease.renewal === 'declined'" class="flex items-center">
                <close2-icon class="w-4 h-4 text-red-600"/>
                <span class="ml-1">Declined</span>
              </div>
              <template v-else>
                {{ lease.renewal }}
              </template>
            </div>
          </div>

          <div v-if="lease.ntvDate" class="cell">
            <div class="title">notice to vacate</div>
            <div class="text-2sm">
              <community-date-time-display :input="lease.ntvDate" format="MM/DD/YYYY" keep-local-time/>
            </div>
          </div>

          <div v-if="lease.moveOutDate" class="cell">
            <div class="title">move-out</div>
            <div class="text-2sm">
              <community-date-time-display :input="lease.moveOutDate" format="MM/DD/YYYY" keep-local-time/>
            </div>
          </div>

          <div v-if="lease.nextLease" class="cell">
            <div class="title">
              future lease
              <Tooltip icon="info" v-if="lease.nextLease.household && lease.nextLease.household.length">
                <div class="flex flex-col">
                  <a
                      v-for="person of lease.nextLease.household"
                      :key="person.id"
                      href="#"
                      class="text-link"
                  >
                    - {{ person.firstName }} {{ person.lastName }}
                  </a>
                </div>
              </Tooltip>
            </div>
            <div class="text-2sm">
              Since <community-date-time-display :input="lease.nextLease.startDate" format="MM/DD/YYYY" keep-local-time/>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="cell">
            <div class="title">lease status</div>
            <div class="text-2sm">
              Not leased
            </div>
          </div>
        </template>
      </div>
    </template>

    <template v-if="financialData">
      <div class="text-sm font-frank font-bold mb-6">financial data</div>
      <div class="bg-graphite-100 border border-graphite-500 p-5 mb-8">
        <div class="flex items-end justify-between">
          <div class="cell">
            <div class="title">lease rent amount</div>
            <div class="font-bold text-lg">
              <financial-data-value type="money" :value="financialData.leaseRent"/>
            </div>
          </div>

          <div
              class="flex items-center cursor-pointer font-frank font-medium text-active-500 text-2sm"
              @click="expandFinancialData = !expandFinancialData"
          >
            <span>comparative analysis</span>
            <chevron-down-icon class="w-5 h-5 transform" :class="expandFinancialData && 'rotate-180'"/>
          </div>
        </div>

        <div v-if="expandFinancialData" class="comparative-analysis">
          <div class="section-title">
            1) could be paid now if…
          </div>
          <div class="flex">
            <div class="cell">
              <div class="title">today's market rent amount</div>
              <div class="text-2sm">
                <financial-data-value type="money" :value="financialData.currentMarketRent"/>
              </div>
            </div>

            <div class="text-graphite-900 text-xl mx-4">=</div>

            <div class="cell">
              <div class="title">today's base rent</div>
              <div class="text-2sm">
                <financial-data-value type="money" :value="financialData.currentBaseRent"/>
              </div>
            </div>

            <div class="text-graphite-900 text-xl mx-4">+</div>

            <div class="cell">
              <div class="title">today's amenities amount</div>
              <div class="text-2sm">
                <financial-data-value type="money" :value="financialData.currentAmntAmount"/>
              </div>
            </div>
          </div>

          <div class="section-title mt-6">
            2) could be paid now if…
          </div>
          <div class="flex">
            <div class="cell">
              <div class="title">lease market rent amount</div>
              <div class="text-2sm">
                <financial-data-value type="money" :value="financialData.leaseMarketRent"/>
              </div>
            </div>

            <div class="text-graphite-900 text-xl mx-6">=</div>

            <div class="cell">
              <div class="title">lease base rent</div>
              <div class="text-2sm">
                <financial-data-value type="money" :value="financialData.leaseBaseRent"/>
              </div>
            </div>

            <div class="text-graphite-900 text-xl mx-6">+</div>

            <div class="cell">
              <div class="title">lease amenities amount</div>
              <div class="text-2sm">
                <financial-data-value type="money" :value="financialData.leaseAmntAmount"/>
              </div>
            </div>
          </div>

          <div class="section-title mt-6">
            3) parameter ratios
          </div>

          <div class="cell col-span-5">
            <div class="title">lease&nbsp;rent&nbsp;amount<br>/&nbsp;lease&nbsp;market&nbsp;rent&nbsp;amount</div>
            <div class="text-2sm">
              <template v-if="financialData.leaseRent && financialData.leaseMarketRent">
                {{ Math.round(financialData.leaseRent / financialData.leaseMarketRent * 100) }}%
              </template>
              <span v-else class="text-graphite-900">
                Not specified
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-if="lease && lease.household && lease.household.length">
      <div class="text-sm font-frank font-bold mb-6">household</div>
      <div
          v-for="person in lease.household"
          :key="person.id"
          class="flex items-center justify-between border p-4 mb-2"
      >
        <div class="font-frank font-bold text-2sm">{{ person.firstName }} {{ person.lastName }}</div>
        <div class="text-2sm">{{ person.role }}</div>
      </div>
    </template>

    <loader :loading="loading" backdrop/>
  </div>
</template>

<script>
import Tooltip from "@/components/ui/Tooltip";
import NotifyMixin from "@/mixins/NotifyMixin";
import Loader from "@/components/ui/Loader";
import CommunityDateTimeDisplay from "@/components/ui/CommunityDateTimeDisplay";
import CheckmarkIcon from "@/components/ui/icons/CheckmarkIcon";
import Close2Icon from "@/components/ui/icons/Close2Icon";
import FinancialDataValue from "@/components/cbr/living_unit/details/lease_info/FinancialDataValue";
import ChevronDownIcon from "@/components/ui/icons/ChevronDownIcon";
import {formatCurrency} from "@/utils/String";

export default {
  components: {ChevronDownIcon, FinancialDataValue, Close2Icon, CheckmarkIcon, CommunityDateTimeDisplay, Loader, Tooltip},

  mixins: [NotifyMixin],

  props: {
    unitId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      expandFinancialData: false,

      lease: undefined,
      financialData: undefined,
    };
  },

  methods: {
    formatCurrency,
  },

  mounted() {
    this.loading = true;

    this.$cbrDataProvider.getUnitLeaseInfo('livingUnits', {unitId: this.unitId})
        .then(({lease, financialData}) => {
          this.lease = lease;
          this.financialData = financialData;
        })
        .catch(error => {
          this.notifyError(error.message);
        })
        .finally(() => {
          this.loading = false;
        });
  }
}
</script>

<style scoped>
.cell {
  .title {
    @apply text-xs font-semibold mb-2;
  }
}

.comparative-analysis {
  /*@apply grid col-gap-4 mt-8;*/
  /*grid-template-columns: 1fr minmax(0, auto) 1fr minmax(0, auto) 1fr;*/
  @apply mt-8;

  .section-title {
    @apply text-2sm font-semibold mb-4 pt-4 col-span-5 border-t border-graphite-400;
  }
}
</style>
