<template>
    <div class="message text-2sm mb-6 relative">
        <div class="flex items-center justify-between mb-2">
            <slot
                name="message-header"
                :message="message"
            >
                <div class="flex items-center lowercase">
                    <slot name="message-header-icon" />
                    <slot
                        name="message-header-datetime"
                        :timestamp="message.timestamp"
                    >
                        <CommunityDateTimeDisplay
                            class="text-gray-500 mr-3"
                            :input="message.timestamp"
                            format="MM/DD/YYYY"
                        />
                    </slot>
                    <slot
                        name="message-header-author-name"
                        :author="message.author"
                    >
                        <span class="text-black font-bold">{{ message.author.nickname }}</span>,
                    </slot>
                    &puncsp;
                    <slot
                        name="message-header-author-role"
                        :author="message.author"
                    >
                        <span class="text-black">{{ message.author.role }}</span>
                    </slot>
                    &puncsp;
                    <span
                        v-if="isEdited && !message.deletedAt"
                        class="text-graphite-800"
                    >(edited)</span>
                </div>
            </slot>
            <template v-if="(editable || deletable) && !message.deletedAt">
                <DropMenu
                    v-if="!editMode"
                    direction="right"
                    min-width="113px"
                    :rounded="false"
                >
                    <template #header>
                        <div class="three-dots">
                            <span /><span /><span />
                        </div>
                    </template>
                    <template #default>
                        <ul class="py-1">
                            <li
                                v-if="editable"
                                class="px-4 py-2 hover:bg-blue-100 text-2sm cursor-pointer"
                                @click="startEdit"
                            >
                                Edit
                            </li>
                            <li
                                v-if="deletable"
                                class="px-4 py-2 hover:bg-blue-100 text-2sm cursor-pointer"
                                @click="deleteMessage"
                            >
                                Delete
                            </li>
                        </ul>
                    </template>
                </DropMenu>
                <div
                    v-else
                    class="flex items-center"
                >
                    <button
                        class="flex items-center justify-center w-6 h-6 rounded-sm hover:bg-graphite-300 cursor-pointer focus:border focus:border-black"
                        title="Cancel"
                        @click="cancelEdit"
                    >
                        <CloseIcon class="w-3 h-3 text-gray-700" />
                    </button>
                    <button
                        class="flex items-center justify-center w-6 h-6 rounded-sm hover:bg-graphite-300 cursor-pointer focus:border focus:border-black"
                        title="Save"
                        @click="saveChanges"
                    >
                        <CheckmarkIcon
                            class="w-3 h-3"
                            :class="messageError ? 'text-gray-300' : 'text-blue-500'"
                        />
                    </button>
                </div>
            </template>
        </div>
        <template v-if="!message.deletedAt">
            <div
                v-if="!editMode"
                class="message-box"
            >
                <TextCollapse
                    v-if="isVisible"
                    :text="message.body.text"
                />
            </div>
            <textarea
                v-else
                ref="textarea"
                v-model="tempBodyText"
                class="w-full border border-blue-300 p-4 outline-none"
            />
            <div
                v-if="messageError"
                class="form-hint form-error"
            >
                {{ messageError }}
            </div>
        </template>
        <div
            v-else
            class="message-box italic"
        >
            This message has been deleted
        </div>
        <loader
            :loading="loading"
            backdrop
        />
    </div>
</template>

<script>
import DropMenu from '@/components/ui/DropMenu';
import TextCollapse from '@/components/ui/TextCollapse';
import CloseIcon from '@/components/ui/icons/CloseIcon';
import CommunityDateTimeDisplay from '@/components/ui/CommunityDateTimeDisplay';
import NotifyMixin from '@/mixins/NotifyMixin';
import Loader from '@/components/ui/Loader';
import ConfirmationMixin from '@/mixins/ConfirmationMixin';
import ValidatorMixin from '@/components/form/ValidatorMixin';
import CheckmarkIcon from '@/components/ui/icons/CheckmarkIcon';

export default {
    components: { CheckmarkIcon, Loader, CommunityDateTimeDisplay, CloseIcon, TextCollapse, DropMenu },

    mixins: [NotifyMixin, ConfirmationMixin, ValidatorMixin],

    props: {
        message: {
            type: Object,
            required: true,
        },

        editable: {
            type: Boolean,
            required: true,
        },

        deletable: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            // to detect when component becomes visible
            // workaround for TextCollapse component inside Tab
            intersectionObserver: null,
            isVisible: false,

            editMode: false,

            tempBodyText: '',
            loading: false,
        };
    },

    computed: {
        messageError() {
            return this.editMode && this.composeValidators(this.required, this.maxLength(5000))(this.tempBodyText);
        },

        isEdited() {
            return this.message.timestamp !== this.message.updatedAt;
        },
    },

    mounted() {
        this.intersectionObserver = new IntersectionObserver(
            entries => {
                this.isVisible = entries[0].isIntersecting;
            },
            { threshold: [0] }
        );

        this.intersectionObserver.observe(this.$el);
    },

    beforeUnmount() {
        this.intersectionObserver.disconnect();
    },

    methods: {
        startEdit() {
            this.tempBodyText = this.message.body.text;
            this.editMode = true;
            this.$nextTick(() => {
                this.$refs.textarea.focus();

                const { scrollHeight, clientHeight, offsetHeight } = this.$refs.textarea;

                if (scrollHeight > clientHeight) {
                    const parentContainer = this.$refs.textarea.closest('.modal-body-container') || document.body;
                    const maxHeightAllowed = (parentContainer.clientHeight / 3) * 2;

                    // the top and bottom borders
                    const offsetCompensation = offsetHeight - clientHeight;

                    this.$refs.textarea.style.height = `${
                        maxHeightAllowed > scrollHeight + offsetCompensation ? scrollHeight + offsetCompensation : maxHeightAllowed
                    }px`;
                }
            });
        },

        cancelEdit() {
            this.editMode = false;
            this.tempBodyText = '';
        },

        async saveChanges() {
            if (this.messageError) {
                return;
            }

            try {
                this.loading = true;
                await this.$chatDataProvider.update('messages', {
                    chatId: this.message.chatId,
                    messageId: this.message.id,
                    data: {
                        body: { text: this.tempBodyText },
                        private: this.message.private,
                        type: this.message.type,
                        subtype: this.message.subtype,
                    },
                });
                this.message.body.text = this.tempBodyText;
                this.editMode = false;
                this.tempBodyText = '';
            } catch (error) {
                this.notifyError(error.message);
            } finally {
                this.loading = false;
            }
        },

        async deleteMessage() {
            if (
                !(await this.requestConfirmation({
                    confirmationType: 'warning',
                    confirmationMessage: 'are you sure you want to delete this item?',
                    confirmBtnText: 'yes, delete',
                    cancelBtnText: 'no, go back',
                }))
            ) {
                return;
            }

            try {
                this.loading = true;
                await this.$chatDataProvider.delete('messages', {
                    chatId: this.message.chatId,
                    messageId: this.message.id,
                });
                // We don't care about the value at this point. Just need to mark as deleted
                this.message.deletedAt = true;
            } catch (error) {
                this.notifyError(error.message);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped>
.message:deep(.drop-menu) {
    @apply p-0;
}

.three-dots {
    @apply flex items-center justify-center cursor-pointer;

    span {
        display: block;
        width: 5px;
        height: 5px;
        background-color: #c4c4c4;
        border-radius: 100%;

        &:not(:last-child) {
            margin-right: 4px;
        }
    }

    &:hover {
        span {
            background-color: #4f98ca;
        }
    }
}

.message-box {
    @apply border border-blue-300 bg-blue-200 text-blue-700 p-4;
    overflow-wrap: anywhere;
}
</style>
