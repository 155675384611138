<template>
  <div class="bg-graphite-200 p-4 flex flex-col overflow-hidden">
    <div class="flex items-center justify-between mb-2">
      <span class="text-sm text-black font-frank font-bold">
        {{ totalUnits }} assigned {{ totalUnits === 1 ? 'unit' : 'units' }}
      </span>
      <EditIcon
          v-if="hasCbrPermission('UTA')"
          class="w-5 h-5 cursor-pointer text-active-500"
          @click="$router.push({name: 'cbr.unittypes.assign', query: {unitTypeId}})"
      />
    </div>
    <div class="mb-6">
      <TextInput
          v-model="searchQuery"
          placeholder="search by unit, building"
      />
    </div>
    <div class="flex-grow overflow-auto flex flex-col text-xs text-blue-800 font-frank font-400">
      <div
          v-for="building of filteredLocations"
          :key="building.id"
          class="mb-4"
      >
        <div class="mb-2">
          building <TextHighlighter :text="building.l2Data.name" :query="searchQuery"/>
        </div>
        <div
            v-for="unit of building.children"
            :key="unit.id"
            class="bg-blue-200 p-2 mb-1 cursor-pointer"
            @click="$router.push({name: 'cbr.living.units.details', params: {unitId: unit.id}})"
        >
          unit <TextHighlighter :text="unit.l2Data.name" :query="searchQuery"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditIcon from "@/components/ui/icons/EditIcon";
import {mapGetters} from "vuex";
import TextInput from "@/components/ui/TextInput";
import TextHighlighter from "@/components/ui/TextHighlighter";

export default {
  components: {TextHighlighter, TextInput, EditIcon},

  props: {
    totalUnits: {
      type: Number,
      required: true,
    },

    unitTypeId: {
      type: String,
      required: true,
    },

    locations: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      searchQuery: '',
    }
  },

  computed: {
    ...mapGetters({
      hasCbrPermission: 'cbr/hasPermission',
    }),

    filteredLocations() {
      if (!this.searchQuery) return this.locations;

      return this.locations.filter(building => (
          building.l2Data.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          building.children.find(unit => unit.l2Data.name.toLowerCase().includes(this.searchQuery.toLowerCase())
          )
      ));
    },
  },
}
</script>
