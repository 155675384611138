<template>
  <span v-if="value !== null">
    {{ textualRepresentation }}
  </span>
  <span v-else class="font-normal text-graphite-900 text-2sm">
    Not specified
  </span>
</template>

<script>
import {formatCurrency} from "@/utils/String";

export default {
  name: "FinancialDataValue",

  props: {
    value: {
      type: Number,
      required: true,
    },

    type: {
      type: String,
      required: true,
      validator: (value) => ['money', 'percent'].includes(value)
    },
  },

  computed: {
    textualRepresentation() {
      switch (this.type) {
        case 'money':
          return formatCurrency(this.value);
        case 'percent':
          return `${this.value}%`;
        default:
          return this.value;
      }
    },
  },
}
</script>
