<template>
  <div class="h-full">
    <list
      :items="assignments"
      resourceLabel="amenities"
      :columns="columns"
      @rowClick="handleRowClick"
    >
      <template v-slot:column:name="{ value }">
        <text-trimmer :text="value" />
      </template>

      <template v-slot:column:description="{ value }">
        <text-trimmer :text="value" />
      </template>

      <template v-slot:column:createdAt="{ value }">
        <div>{{ formatDateTime(value, "MMM DD, YYYY") }}</div>
        <div>{{ formatDateTime(value, "hh:mm a") }}</div>
      </template>

      <template v-slot:column:total="{ value }">
        <amount-input :value="value" prefix="$" readOnly />
      </template>
    </list>
    <loader :loading="loading" backdrop />
  </div>
</template>

<script>
import CommunityTimezoneMixin from '@/mixins/CommunityTimezoneMixin';
import ModalNavigation from "@/mixins/ModalNavigation";
import NotifyMixin from "@/mixins/NotifyMixin";
import AmountInput from "@/components/ui/AmountInput";
import Loader from '@/components/ui/Loader';
import TextTrimmer from '@/components/ui/TextTrimmer';
import List from '@/components/list/List';

export default {
  name: 'LivingUnitAmenities',

  components: {
    AmountInput,
    Loader,
    List,
    TextTrimmer,
  },

  mixins: [
    CommunityTimezoneMixin,
    NotifyMixin,
    ModalNavigation,
  ],

  data() {
    return {
      loading: false,
      assignments: [],
      columns: [
        {
          name: 'name',
          title: 'individual amenities and groups',
          class: 'w-3/12',
        },
        {
          name: 'description',
          title: 'description',
          class: 'w-5/12',
        },
        {
          name: 'total',
          title: 'price with discount(s)',
          class: 'w-2/12 text-right',
        },
        {
          name: 'createdAt',
          title: 'assignment date',
          class: 'w-2/12 text-right',
        },
      ],
    };
  },

  computed: {
    locationId(){
      return this.$route.params.unitId;
    }
  },

  methods: {
    fetchAssignments(){
      if(this.loading) {
        return;
      }

      this.loading = true;

      this.$amntDataProvider.getList('locationAssignments', { locationId: this.locationId })
        .then(assignments => this.assignments = assignments)
        .catch(error => this.notifyError(error.message))
        .finally(() => this.loading = false);
    },
    handleRowClick({ id : amenityId, type }){
      this.$router.push({
        name: type === 'GROUP'
          ? 'cbr.living.units.groups.details'
          : 'cbr.living.units.amenities.details',
        params: {
          unitId: this.locationId,
          amenityId,
        }
      });
    }
  },

  mounted(){
    this.fetchAssignments();
  }
}
</script>
