<template>
  <FinalField :name="name" :validate="validate" ref="field">
    <template v-slot="props">
      <div class="form-col">
        <div class="flex items-baseline">
          <label v-if="label">{{label}}</label>
          <div v-if="editMode" class="flex-grow flex justify-end">
            <button
                type="button"
                class="font-bold font-frank text-xs focus:outline-none mr-1"
                :class="rangeMode ? 'text-gray-500' : 'text-blue-500'"
                @click="rangeMode = false"
            >
              number
            </button>
            <button
                type="button"
                class="font-bold font-frank text-xs focus:outline-none"
                :class="rangeMode ? 'text-blue-500' : 'text-gray-500'"
                @click="rangeMode = true"
            >
              range
            </button>
          </div>
        </div>
        <NumberRangeInput
            v-if="editMode"
            :range-mode="rangeMode"
            :value="props.value"
            :precision="precision"
            @input="props.change"
            @focus="handleFocus"
            @blur="handleBlur"
        />
        <span v-else>
          {{getValueString(props.value)}}
        </span>

        <span class="form-hint" v-if="helperText">{{helperText}}</span>
        <span class="form-hint form-error" v-if="(props.meta.error && props.meta.touched) || (props.meta.submitError && !props.meta.dirtySinceLastSubmit)">
          {{ (props.meta.error || props.meta.submitError) }}
        </span>
      </div>
    </template>
  </FinalField>
</template>

<script>
import {FinalField} from "vue-final-form";
import NumberRangeInput from "@/components/ui/NumberRangeInput";
import ValidatorMixin from "@/components/form/ValidatorMixin";

export default {
  name: "NumberRangeField",

  components: {NumberRangeInput, FinalField},

  mixins: [ValidatorMixin],

  props: {
    name: {
      type: String,
      required: true,
    },

    label: {
      type: String,
    },

    validate: {
      type: [Function, Array],
      required: false,
    },

    disabled: {
      type: Boolean,
      default: false
    },

    editMode: {
      type: Boolean,
      default: true,
    },

    helperText: {
      type: String,
    },

    /**
     * Amount precision
     */
    precision: {
      type: Number,
      default: 2,
    },
  },

  data() {
    return {
      rangeMode: true,
    };
  },

  methods: {
    handleFocus() {
      this.$refs.field.fieldState.focus();
    },

    handleBlur() {
      this.$refs.field.fieldState.blur();
    },

    getValueString(value) {
      const min = value?.[0];
      const max = value?.[1];

      if (isNaN(min) || isNaN(max)) {
        return '-';
      }

      return min === max ? min : `${min}-${max}`;
    },
  },

  mounted() {
    const {value} = this.$refs.field.fieldState;
    if (value?.[0] === value?.[1]) {
      this.rangeMode = false;
    }
  }
}
</script>
