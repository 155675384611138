<template>
  <div class="h-full">
    <div class="columns-wrapper">
      <div class="flex flex-col bg-gray-100 p-4">
        <div class="text-sm font-frank font-bold mb-2">unit types</div>
<!--        <div class="mb-4">-->
<!--          <input-->
<!--              type="text"-->
<!--              placeholder="Search by unit types"-->
<!--              class="w-full border text-sm p-3"-->
<!--          />-->
<!--        </div>-->
        <div class="flex flex-col flex-grow overflow-auto">
          <div
              v-for="unitType of unitTypes"
              :key="unitType.id"
              class="flex items-center justify-between border p-3 mb-2 cursor-pointer"
              :class="{'border-blue-500': unitType.id === selectedUnitTypeId}"
              @click="selectedUnitTypeId = unitType.id"
          >
            <div
                class="text-2sm font-normal font-frank lowercase"
                :class="unitType.id === selectedUnitTypeId ? 'text-blue-500' : 'text-black'"
            >
              {{unitType.name}}
            </div>
            <div class="text-xs font-medium text-gray-500 bg-gray-200 py-1 px-2 rounded-full">
              {{unitType.totalNumberOfUnits}}
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col">
<!--        <FilterBlock-->
<!--            :filters="[]"-->
<!--            :values="{}"-->
<!--            search-placeholder-text="Search by buildings and units"-->
<!--            @change="() => {}"-->
<!--            class="mb-4"-->
<!--        />-->

        <div class="text-xs text-black font-frank font-bold">{{totalAssignedUnits}} units assigned</div>
        <div class="tree-list">
          <div class="head">
            <div class="row">
              <div class="column w-3/12 flex items-center">
                <Icon
                  :name="allExpanded ? 'minusSquare' : 'plusSquare'"
                  class="w-3 h-3 mr-2 cursor-pointer"
                  @click="toggleAll"
                />
                <span>building</span>
              </div>
              <div class="column w-2/12">unit</div>
              <div class="column w-3/12">unit type</div>
              <div class="column w-3/12">actions</div>
            </div>
          </div>
          <div class="body" v-for="building in buildings" :key="building.id">
            <div class="row">
              <div
                class="column w-8/12 flex items-center cursor-pointer"
                @click="toggleBuildingExpand(building)"
              >
                <Icon
                  v-if="building.totalUnits > 0"
                  :name="building.expanded ? 'minusSquare' : 'plusSquare'"
                  class="w-3 h-3 mr-2 cursor-pointer"
                />
                <span :class="{'ml-5': building.totalUnits === 0}">{{building.name}}</span>
              </div>
              <div class="column w-3/12">
                <span
                  v-if="isAssignAllVisible(building)"
                  class="link"
                  @click="assignAll(building)"
                >
                  <Icon name="plusCircle" class="inline-block align-text-top w-4 h-4" />
                  assign all units to {{selectedUnitType.name}}
                </span>
              </div>
            </div>
            <div v-if="building.expanded" class="bg-gray-70">
              <div v-if="building.loading" class="row">
                <BeatLoader class="mx-auto my-2" loading/>
              </div>
              <div
                v-for="unit in unitsOfBuilding(building)"
                :key="unit.id"
                class="row"
                :class="{'font-bold': selectedUnitTypeId && unitHasSelectedType(unit)}"
              >
                <div class="column w-3/12"/>
                <div class="column w-2/12">{{unit.name}}</div>
                <div class="column w-3/12 unit-type overflow-visible">
                  <Dropdown
                    class="font-normal"
                    :ref="handleDropdownRef"
                    v-if="unit.editing"
                    :options="unitTypeOptions"
                    :searchable="true"
                    :no-clear="true"
                    :value="unit.unitType ? unit.unitType.id : null"
                    @input="handleSingleUnitChange($event, unit)"
                    @close="unit.editing = false"
                  />
                  <span
                    v-else
                    class="inline-flex items-center cursor-pointer"
                    @click.stop="editType(unit)"
                  >
                    <span v-if="unit.unitType">{{unit.unitType.name}}</span>
                    <span v-else class="text-gray-600">Unassigned</span>
                    <Icon name="pencil2" class="w-3 h-3 ml-1 cell-edit"/>
                  </span>
                </div>
                <div class="column w-3/12" :key="unit.assignmentStatus">
                  <span
                      v-if="selectedUnitTypeId && !unitHasSelectedType(unit) && !unit.assignmentStatus"
                      class="link"
                      @click="assignSingleUnit(unit, selectedUnitType)"
                  >
                    assign to {{selectedUnitType.name}}
                  </span>
                  <AssignmentStatus v-else :status="unit.assignmentStatus"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalNavigation from "@/mixins/ModalNavigation";
import ConfirmationMixin from "@/mixins/ConfirmationMixin";
// import FilterBlock from "@/components/list/FilterBlock";
import Icon from "@/components/ui/Icon";
import Dropdown from "@/components/ui/Dropdown";
import NotifyMixin from "@/mixins/NotifyMixin";
import BeatLoader from "@/components/ui/BeatLoader";
import AssignmentStatus from "@/components/ui/AssignmentStatus";
import { ASSIGNMENT_STATUSES } from "@/components/ui/AssignmentStatus";

export default {
  components: {AssignmentStatus, BeatLoader, Dropdown, Icon/*, FilterBlock*/},

  mixins: [ModalNavigation, ConfirmationMixin, NotifyMixin],

  data() {
    return {
      unitTypes: [],
      buildings: [],
      units: [],
      selectedUnitTypeId: null,
    };
  },

  computed: {
    selectedUnitType() {
      return this.unitTypes.find(({id}) => id === this.selectedUnitTypeId);
    },

    unitTypeOptions() {
      return this.unitTypes.map(unitType => ({
        key: unitType.id,
        value: unitType.name,
      }));
    },

    allExpanded() {
      return this.buildings.every(building => building.expanded);
    },

    totalAssignedUnits() {
      return this.unitTypes.reduce((acc, cv) => acc + cv.totalNumberOfUnits, 0);
    },
  },

  methods: {
    unitsOfBuilding(building) {
      return this.units.filter(unit => unit.buildingId === building.id);
    },

    loadBuildingUnits(building) {
      building.loading = true;

      this.$cbrDataProvider.getList('livingUnits', {buildingId: building.id, limit: 999999})
          .then(({items}) => {
            this.units = [...this.units, ...items.map(({value}) => ({
              ...value,
              buildingId: building.id,
              editing: false,
              assignmentStatus: null,
            }))];
          })
          .catch(error => this.notifyError(error.message))
          .finally(() => {
            building.loading = false;
            this.$forceUpdate();
          });
    },

    toggleBuildingExpand(building) {
      building.expanded = !building.expanded;

      if (building.expanded && building.totalUnits && this.unitsOfBuilding(building).length === 0) {
        this.loadBuildingUnits(building);
      }
    },

    toggleAll() {
      const allExpanded = this.allExpanded;

      this.buildings.forEach(building => {
        building.expanded = !allExpanded;

        if (building.expanded && building.totalUnits && this.unitsOfBuilding(building).length === 0) {
          this.loadBuildingUnits(building);
        }
      });
    },

    isAssignAllVisible(building) {
      if (building.expanded) {
        return this.selectedUnitType && this.unitsOfBuilding(building).some(unit => unit.unitType?.id !== this.selectedUnitTypeId);
      } else {
        // TODO: find the way to check unit types without preloading them
        return this.selectedUnitType && building.totalUnits > 0;
      }
    },

    unitHasSelectedType(unit) {
      return unit.unitType?.id === this.selectedUnitTypeId;
    },

    handleSingleUnitChange(typeId, unit) {
      this.assignSingleUnit(unit, this.unitTypes.find(({id}) => id === typeId));
    },

    assignSingleUnit(unit, selectedUnitType) {
      if (unit.unitType?.id === selectedUnitType.id) return;

      unit.assignmentStatus = ASSIGNMENT_STATUSES.IN_PROGRESS;
      this.$cbrDataProvider.updateUnitType('livingUnits', {
        unitId: unit.id,
        data: {
          id: selectedUnitType.id,
        },
      }).then(() => {
        unit.assignmentStatus = ASSIGNMENT_STATUSES.SUCCESS;
        selectedUnitType.totalUnits++;
        unit.unitType = selectedUnitType;
      }).catch(error => {
        unit.assignmentStatus = ASSIGNMENT_STATUSES.FAILED;
        this.notifyError(error.message);
      }).finally(() => {
        setTimeout(() => {
          unit.assignmentStatus = null;
        }, 2000);
      });
    },

    assignAll(building) {
      this.requestConfirmation({
        confirmationMessage: `Are you sure you want to assign unit type ${this.selectedUnitType.name} to all units in ${building.name}?`,
        confirmBtnText: 'yes, assign',
        cancelBtnText: 'cancel',
        confirmationType: 'success'
      }).then(confirmed => {
        if (confirmed) {
          this.$cbrDataProvider.batchUpdateUnitType('livingBuildings', {
            id: building.id,
            data: {
              id: this.selectedUnitTypeId,
            },
          }).then(assignedCount => {
            this.selectedUnitType.totalUnits += assignedCount;
            this.unitsOfBuilding(building).forEach(unit => {
              unit.unitType = this.selectedUnitType;
            });
            this.notifySuccess(`assigned ${assignedCount} units to ${this.selectedUnitType.name}`);
          }).catch(error => {
            this.notifyError(error.message);
          });
        }
      });
    },

    editType(unit) {
      unit.editing = true;
    },

    handleDropdownRef(ref) {
      if (ref) {
        ref.openDropdown();
      }
    },
  },

  mounted() {
    this.$cbrDataProvider.getList('unitTypes', {limit: 999999})
        .then(({items}) => {
          this.unitTypes = items.map(({value}) => value);

          const {unitTypeId} = this.$route.query;
          if (unitTypeId) {
            this.selectedUnitTypeId = this.unitTypes.find(({id}) => id === unitTypeId)?.id;

            if (this.backModal.routeName === 'cbr.unittypes.create') {
              this.removeModalByRouteName('cbr.unittypes.create');
              this.pushModal({
                title: 'unit type',
                breadcrumbName: 'unit type',
                routeName: 'cbr.unittypes.details',
                params: {typeId: unitTypeId},
                template: '',
              });
            }
          }
        });

    this.$cbrDataProvider.getList('livingBuildings', {limit: 999999})
        .then(({items}) => {
          this.buildings = items.map(({value}) => ({...value, ...value.stats, expanded: false}));
        });

    const {unitId} = this.$route.query;
    if (unitId) {
      // TODO: set selected unit
    }
  },
}
</script>

<style scoped>
.columns-wrapper {
  @apply grid grid-rows-1 col-gap-6 w-full h-full;
  grid-template-columns: 1fr 2fr;
}

.tree-list {
  @apply overflow-auto text-black font-normal text-xs w-full;
}

.row {
  @apply flex;
}

.head {
  @apply sticky top-0 z-10;

  .row {
    @apply bg-white border-b border-black;

    .column {
      @apply font-frank font-bold p-3;
    }
  }
}

.body {
  .row {
    @apply items-center border-b border-gray-200;

    &:hover {
      @apply bg-blue-100;
    }

    .column {
      @apply border-none p-3 whitespace-no-wrap;

      &.unit-type {
        @apply py-0;
      }
    }

    .column:not(.overflow-visible) {
      @apply overflow-x-hidden overflow-ellipsis;

      .text {
        @apply overflow-hidden overflow-ellipsis inline-block max-w-full;
      }
    }

    &:not(:hover) {
      .cell-edit {
        @apply hidden;
      }
    }
  }
}

.link {
  @apply font-frank cursor-pointer;
  color: #4f98ca;
}

.unit-type:deep(.form-input) {
  @apply py-1;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
}

.unit-type:deep(.dropdown__item--empty) {
  @apply justify-start;
}
</style>
