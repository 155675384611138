<template>
  <div class="h-full">
    <FinalForm
      class="h-full"
      :submit="handleFormSubmit"
      :initial-values="initialValues"
    >
      <template v-slot="props">
        <form
          class="h-full"
          @submit="props.handleSubmit"
        >
          <div class="grid grid-cols-2 gap-8">
            <div>
              <div class="form-row">
                <TextField
                  name="name"
                  label="name*"
                  :validate="required"
                />
              </div>
              <div class="form-row">
                <SelectInput
                  name="structureType"
                  label="structure type*"
                  :validate="required"
                  :data-provider="$cbrDataProvider"
                  resource="buildingStructureTypes"
                  option-key="id"
                  option-label="name"
                  object-mode
                  preselect-first-option
                />
              </div>
            </div>
            <div class="form-row">
              <TextField
                name="description"
                label="description*"
                multiline
                rows="5"
                :validate="required"
              />
            </div>
          </div>
          <ModalFooter
            :footer="footer"
            :tertiary="close"
          />
        </form>
      </template>
    </FinalForm>
    <Loader :loading="loading" backdrop/>
  </div>
</template>

<script>
import {FinalForm} from 'vue-final-form';
import ModalNavigation from '@/mixins/ModalNavigation';
import NotifyMixin from '@/mixins/NotifyMixin';
import ValidatorMixin from '@/components/form/ValidatorMixin';
import Loader from '@/components/ui/Loader';
import ModalFooter from '@/components/ui/modals/ModalFooter';
import TextField from '@/components/form/TextField';
import SelectInput from "@/components/form/SelectInput";

export default {
  mixins: [
    ModalNavigation,
    NotifyMixin,
    ValidatorMixin
  ],

  components: {
    ModalFooter,
    TextField,
    SelectInput,
    FinalForm,
    Loader,
  },

  data() {
    return {
      loading: false,
      initialValues: {},
      footer: {
        primaryButton: 'submit',
        tertiaryButton: 'cancel',
      },
    };
  },

  methods: {
    async handleFormSubmit({structureType, ...values}) {
      const data = {
        ...values,
        structureType: structureType && {
          id: structureType.id,
        },
      };

      try {
        this.loading = true;

        await this.$cbrDataProvider.create('livingBuildings', {data});

        this.notifySuccess('Building successfully saved');
        this.close();
      } catch (error) {
        this.notifyError(error.message);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
