<template>
  <FinalForm
    ref="form"
    class="h-full"
    :initialValues="initialValues"
  >
    <template v-slot="{values}">
      <form
        class="form-narrow flex flex-col justify-between min-h-full h-full"
      >
        <div class="flex flex-grow overflow-y-auto">
          <div class="form-col w-2/5">
            <div class="form-row">
              <TextField
                name="name"
                label="amenity name"
                :edit-mode="false"
              />
            </div>
            <div class="form-row">
              <SelectInput
                name="chargeCodeId"
                label="charge code"
                :options="chargeCodesOptions"
                :edit-mode="false"
              />
            </div>
          </div>
          <div class="form-col flex-grow">
            <div class="form-row">
              <TextField
                name="description"
                label="description"
                placeholder="Provide an explanation to accurately understand this amenity"
                rows="6"
                multiline
                :edit-mode="false"
              />
            </div>
          </div>
        </div>
        <TotalPanel
          :edit-mode="false"
          level="LOCATION"
          :is-group="false"
          :values="values"
        />
      </form>
      <loader :loading="loading" :backdrop="true"/>
    </template>
  </FinalForm>
</template>

<script>
import {mapGetters} from 'vuex';
import {FinalForm} from 'vue-final-form';
import ValidatorMixin from '@/components/form/ValidatorMixin';
import ModalNavigation from "@/mixins/ModalNavigation";
import NotifyMixin from '@/mixins/NotifyMixin';
import ActionsMixin from '@/mixins/ActionsMixin';
import InitializeFormMixin from '@/components/form/InitializeFormMixin';
import TextField from '@/components/form/TextField';
import SelectInput from '@/components/form/SelectInput';
import TotalPanel from '@/components/amenities/TotalPanel';
import Loader from '@/components/ui/Loader';

export default {
  name: 'LivingUnitAmenityDetails',

  components: {
    TotalPanel,
    SelectInput,
    TextField,
    Loader,
    FinalForm,
  },

  mixins: [
    NotifyMixin,
    ValidatorMixin,
    ModalNavigation,
    InitializeFormMixin,
    ActionsMixin,
  ],

  data() {
    return {
      loading: true,
      initialValues: {},
      chargeCodesOptions: [],
    };
  },

  computed: {
    ...mapGetters({
      hasPermission: 'amnt/hasPermission',
    }),
    locationId(){
      return this.$route.params.unitId;
    },
    amenityId() {
      return this.$route.params.amenityId;
    },
  },

  methods: {
    fetchAmenity(){
      this.$amntDataProvider.getOne('locationAssignments', {
        locationId: this.locationId,
        locationAmenityId: this.amenityId,
        type: 'amenities',
      })
      .then(({
          communityAmenity: {
            chargeCode,
            discount,
            discountType,
            total,
            amenity: {
              name,
              description,
              price: globalPrice,
              discount: globalDiscount,
              discountType: globalDiscountType,
            },
          },
        }) => {

        if(chargeCode){
          this.chargeCodesOptions = [{
            key: chargeCode.id,
            value: chargeCode.description,
          }];
        }

        this.initialValues = {
          name,
          description,
          chargeCodeId: chargeCode?.id || null,
          globalPrice,
          globalDiscount,
          globalDiscountType,
          discount,
          discountType,
          total,
        };

        this.setActiveModalName(name);
      })
      .catch(error => this.notifyError(error.message))
      .finally(() => this.loading = false);
    },

    unassignAmenity() {
      this.$amntDataProvider.delete('locationAssignments', {
        locationId: this.locationId,
        locationAmenityId: this.amenityId,
        type: 'amenities',
      })
      .then(() => {
        this.notifySuccess('Item was successfully unassigned');
        this.close();
      })
      .catch(error => this.notifyError(error.message));
    }
  },

  mounted() {
    this.fetchAmenity();

    if(this.hasPermission('UL_M')){
      this.addConfirmAction(
        {
          id: 'amnt-unassign-amenity',
          title: 'unassign from unit',
          showConfirmation: true,
          confirmationMessage: 'are you sure you want to unassign this item?',
          confirmBtnText: 'yes, unassign',
          cancelBtnText: 'no, go back',
          confirmationType: 'warning'
        },
        this.unassignAmenity
      );
    }
  }
}
</script>
