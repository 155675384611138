<template>
  <div class="location-tree">
    <div
      v-if="hasLocations"
      class="location-item"
      :class="{ 'location-item--temp': location.isTemp }"
    >
      <div
        v-if="isRegular && location"
        ref="item"
        class="location-item__line"
        :class="{
          ['location-item-' + locationId]: locationId,
          'location-item__line--selected': isSelected,
        }"
        @click="handleSelect"
      >
        <div v-if="!forceExpanded">
          <Icon
            v-if="mayHaveChildren"
            :name="expanded ? 'minusSquare' : 'plusSquare'"
            class="location-item__expand-icon"
            @click.stop="handleExpand"
          />
          <div v-else class="w-4 flex-shrink-0" />
        </div>

        <Checkbox
          v-if="displayCheckbox"
          class="mx-1"
          :value="checkboxSelected"
          @change="(checked) => checkLocations([locationId], checked)"
          :disabled="checkboxDisabled"
        />

        <div class="location-item__heading">
          <LocationIndicator
            class="mx-1"
            :businessPurpose="location.businessPurpose"
          />

          <div class="location-item__data">
            <span
              v-if="!searchQuery || !location.children"
              class="location-item__name"
              >{{ location.name }}</span
            >
            <span v-else v-html="highlight(location.name)" class="location-item__name" />
            <span>{{ structureTypeName }}</span>
          </div>

          <div
            v-if="displayCheckbox && checkboxActions"
            class="location-item__inline-actions"
            :class="{'location-item__inline-actions--persisting': checkboxSelected && mayHaveChildren}"
          >
            <button
              v-if="mayHaveChildren && !allChildrenSelected"
              class="btn-primary btn-transparent ml-3"
              type="button"
              @click.stop="checkAllChildren"
            >
              select all children
            </button>
            <button
              v-if="mayHaveChildren && !allChildrenUnselected"
              class="btn-primary btn-transparent ml-3"
              type="button"
              @click.stop="uncheckAllChildren"
            >
              unselect all children
            </button>
          </div>

          <div v-if="showContextActions" class="location-item__context-actions">
            <span v-if="withAddChild" title="add child location">
              <plus-circle-icon
                class="w-4 h-4 mx-2"
                @click.stop="$emit('add-child', locationId)"
              />
            </span>
            <drop-menu
              v-if="
                withDelete &&
                (!location.children || location.children.length === 0)
              "
              class="delete-dropdown"
              direction="right"
              min-width="50px"
              remove-padding
            >
              <template #header>
                <dots-vertical-icon class="transform rotate-90 w-3 h-3" />
              </template>
              <template #default>
                <ul>
                  <li
                    class="text-gray-700 px-4 py-2 hover:bg-blue-100"
                    @click.stop="$emit('delete', locationId)"
                  >
                    delete
                  </li>
                </ul>
              </template>
            </drop-menu>
          </div>
        </div>
      </div>

      <BeatLoader v-if="loading" class="ml-4 py-1" />

      <div
        v-if="displayChildren && location.children"
        class="location-item__children"
        :class="{ 'location-item__children--regular': isRegular }"
      >
        <LocationTree
          ref="children"
          v-for="childId in location.children"
          :key="childId"
          :location-id="childId"
          :level="level + 1"
          @select="$emit('select', $event)"
          @add-child="$emit('add-child', $event)"
          @delete="$emit('delete', $event)"
          @check="$emit('check', $event)"
          v-bind="{
            rootId,
            selectedId,
            checkedLocations,
            selectable,
            locations,
            mode,
            searchQuery,
            expandAll,
            withAddChild,
            withDelete,
            creatingLocation,
            checkboxSelection,
            checkboxActions,
            disabledCheckboxes,
            forceExpanded,
            expandLevel,
            expandCallback,
            renderRoot,
          }"
        />
      </div>
    </div>

    <div
      v-else
      class="flex items-center justify-center bg-blue-100 border border-blue-300 text-blue-700 py-6 px-2"
    >
      <Icon name="exclamationCircle" class="w-4 h-4 min-w-4" />
      <span class="font-frank font-medium text-sm ml-2">{{ emptyText }}</span>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/ui/Icon';
import NotifyMixin from '@/mixins/NotifyMixin';
import BeatLoader from '@/components/ui/BeatLoader';
import LocationIndicator from '@/components/ui/location-tree/LocationIndicator';
import {
  TREE_MODE_L1,
  INIT_LEVEL,
  ROOT_LOCATION_ID,
} from '@/utils/constants/location-tree';
import PlusCircleIcon from '@/components/ui/icons/PlusCircleIcon';
import DotsVerticalIcon from '@/components/ui/icons/DotsVerticalIcon';
import DropMenu from '@/components/ui/DropMenu';
import Checkbox from '@/components/ui/Checkbox';
import { prepareWordsModeHighlighter } from '@/utils/highlighting';

export default {
  name: 'LocationTree',

  components: {
    DropMenu,
    DotsVerticalIcon,
    PlusCircleIcon,
    BeatLoader,
    Icon,
    Checkbox,
    LocationIndicator,
  },

  mixins: [NotifyMixin],

  props: {
    /**
     * Location id
     */
    locationId: {
      type: [String, Number],
      required: true,
    },

    /**
     * Root location id
     */
    rootId: {
      type: String,
      required: true,
    },
    /**
     * Locations object `{ id: location }`
     */
    locations: {
      type: Object,
      default: () => ({}),
    },

    /**
     * Selected location id
     */
    selectedId: {
      type: String,
      required: false,
    },

    /**
     * Force location child nodes expanding
     */
    forceExpanded: {
      type: Boolean,
      default: false,
    },

    /**
     * Location mode
     */
    mode: {
      type: String,
      default: TREE_MODE_L1,
    },

    /**
     * Allow select location node by click
     */
    selectable: {
      type: Boolean,
      default: false,
    },

    /**
     * Search text to highlight
     */
    searchQuery: {
      type: String,
      required: false,
    },

    /**
     * Expand all nodes
     */
    expandAll: {
      type: Boolean,
      default: false,
    },

    /**
     * Display add child button
     */
    withAddChild: {
      type: Boolean,
      default: false,
    },

    /**
     * Display delete button
     */
    withDelete: {
      type: Boolean,
      default: false,
    },

    creatingLocation: {
      type: Boolean,
      default: false,
    },

    /**
     * Add checkbox selection. 
     * Accept `Boolean` (set `true` to display near each node), 
     * `String[]` or `Number[]` which contain location ids with checkbox displayed
     * or custom function `((location: Object) => boolean)` to implement checkbox displaying yourself
     */
    checkboxSelection: {
      type: [Boolean, Array, Function],
      default: false,
    },

    /**
     * Show checkbox actions
     */
    checkboxActions: {
      type: Boolean,
      default: false,
    },

    /**
     * Disabled checkboxes
     * Accept `Boolean` (set `true` to display near each node), 
     * `String[]` or `Number[]` which contain location ids with checkbox displayed
     * or custom function `((location: Object) => boolean)` to implement checkbox displaying yourself
     */
    disabledCheckboxes: {
      type: [Boolean, Array, Function],
      default: false,
    },

    /**
     * Checkbox selected nodes
     */
    checkedLocations: {
      type: Array,
      required: false,
    },
    /**
     * Current level
     */
    level: {
      type: Number,
      default: INIT_LEVEL,
    },
    /**
     * Set expanding level
     */
    expandLevel: {
      type: Number,
      required: false,
    },
    /**
     * Expand handler function `(locationId) => {}`
     */
    expandCallback: {
      type: Function,
      required: false,
    },
    /**
     * Render root node
     */
    renderRoot: {
      type: Boolean,
      default: false
    },

    /**
     * Indicate whether any filters are applied
     */
    hasFilters: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    /**
     * Emitted on node select
     */
    'select',
    /**
     * Emmited on add-child button click
     */
    'add-child',
    /**
     * Emitted on node delete button click
     */
    'delete',
    /**
     * Emitted on node checkbox change
     */
    'check',
  ],

  data() {
    return {
      loading: false,
      expanded: false,
    };
  },

  computed: {
    location() {
      return this.locations[this.locationId];
    },

    mayHaveChildren() {
      // may have children if `children` array is nil or it has items
      return this.location.children?.length !== 0;
    },

    isRegular() {
      return this.locationId !== this.rootId || this.renderRoot;
    },

    isSelected() {
      return this.locationId === this.selectedId;
    },

    showContextActions() {
      return (
        this.isSelected &&
        (this.withAddChild || this.withDelete) &&
        !this.creatingLocation
      );
    },

    creatingChild() {
      return this.isSelected && this.creatingLocation;
    },

    checkboxSelected() {
      return (
        this.checkedLocations?.findIndex((id) => id === this.locationId) >= 0
      );
    },

    checkboxDisabled(){
      if(Array.isArray(this.disabledCheckboxes)){
        return this.disabledCheckboxes.includes(this.locationId);
      }

      if(typeof this.disabledCheckboxes === 'function'){
        return this.disabledCheckboxes(this.location);
      }

      return !!this.disabledCheckboxes;
    },

    allChildrenSelected() {
      if (!this.checkedLocations) {
        return false;
      }

      const childIdsWithCheckboxes = this.getChildIdsWithCheckboxes(this.locationId);

      return childIdsWithCheckboxes.every((id) => this.checkedLocations.includes(id));
    },

    allChildrenUnselected() {
      if (!this.checkedLocations) {
        return false;
      }

      const childIdsWithCheckboxes = this.getChildIdsWithCheckboxes(this.locationId);

      return childIdsWithCheckboxes.every((id) => !this.checkedLocations.includes(id));
    },

    highlight() {
      return prepareWordsModeHighlighter(this.searchQuery || '');
    },

    displayChildren() {
      return (
        !this.isRegular ||
        this.expanded ||
        this.forceExpanded ||
        this.searchQuery
      );
    },

    displayCheckbox(){
      if(Array.isArray(this.checkboxSelection)){
        return this.checkboxSelection.includes(this.location.id);
      }

      if(typeof this.checkboxSelection === 'function'){
        return this.checkboxSelection(this.location);
      }

      return !!this.checkboxSelection;
    },

    structureTypeName(){
      return this.location?.structureType?.name;
    },

    hasLocations(){
      const locationsIds = Object.keys(this.locations);

      return this.renderRoot ? locationsIds.length > 0 : locationsIds.filter(item => item !== ROOT_LOCATION_ID).length > 0;
    },

    emptyText(){
      return this.hasFilters ? ' no locations found' : 'no available locations yet';
    },
  },

  methods: {
    async handleExpand() {
      try {
        //fetch only on lazy load
        if (this.expandCallback && !this.expanded && !this.location.children) {
          this.loading = true;
          await this.expandCallback(this.locationId);
        }

        this.expanded = !this.expanded;
      } catch (e) {
        this.notifyError(e.message);
      } finally {
        this.loading = false;
      }
    },

    handleSelect() {
      if (!this.location.isTemp && this.selectable) {
        this.$emit('select', this.locationId);
      }
    },

    checkAllChildren() {
      const childIdsWithCheckboxes = this.getChildIdsWithCheckboxes(this.locationId);
      const newlyCheckedLocations = childIdsWithCheckboxes.filter(
        (id) => !this.checkedLocations.includes(id)
      );

      this.checkLocations(newlyCheckedLocations, true);
    },

    uncheckAllChildren() {
      const childIdsWithCheckboxes = this.getChildIdsWithCheckboxes(this.locationId);
      const uncheckedLocations = childIdsWithCheckboxes.filter((id) =>
        this.checkedLocations.includes(id)
      );

      this.checkLocations(uncheckedLocations, false);
    },

    checkLocations(value, checked = true) {
      this.$emit('check', { value, checked });
    },

    getChildIds(locationId) {
      const { children } = this.locations[locationId];

      if (!Array.isArray(children)) {
        return [];
      }

      return children.map((id) => this.getTreeIds(id)).flat();
    },

    getTreeIds(locationId) {
      const locationChildren = this.locations[locationId]?.children;

      if (locationChildren) {
        return [
          locationId,
          ...locationChildren.map((id) => this.getTreeIds(id)),
        ].flat();
      }

      return locationId;
    },

    filterIdsWithCheckboxes(locationIds){
      if(typeof this.checkboxSelection === 'function'){
        return locationIds.filter(id => this.checkboxSelection(this.locations[id]));
      }
      
      if(Array.isArray(this.checkboxSelection)) {
        return locationIds.filter(id => this.checkboxSelection.includes(id));
      } 

      if(!this.checkboxSelection) {
        return [];
      }

      return locationIds;
    },
    
    getChildIdsWithCheckboxes(locationId){
      const childIds = this.getChildIds(locationId);
      const childIdsWithCheckboxes = this.filterIdsWithCheckboxes(childIds);

      return childIdsWithCheckboxes;
    },
  },

  created() {
    if (this.expandAll || (this.expandLevel && this.level < this.expandLevel)) {
      this.expanded = true;
    }
  },

  beforeUnmount() {
    if (this.isSelected && this.selectable) {
      this.$emit('select', null);
    }
  },
};
</script>

<style scoped>
.location-item--temp .location-item__heading {
  @apply border border-dashed border-active-500 cursor-auto;
}

.location-item--temp .location-item__line {
  @apply my-1;
}

.location-item__inline-actions:not(.location-item__inline-actions--persisting) {
  @apply hidden;
}

.location-item:not(.location-item--temp) > .location-item__line > .location-item__heading {
  @apply cursor-pointer;
}

.location-item:not(.location-item--temp) > .location-item__line:not(.location-item__line--selected) > .location-item__heading:hover  {
  @apply bg-blue-200;

  .location-item__inline-actions {
    @apply block;
  }
}

.location-item__line {
  @apply flex w-full h-6 items-center text-xs;
}

.location-item__heading {
  @apply flex items-center flex-grow;
}

.location-item__line--selected .location-item__heading {
  @apply bg-blue-500 text-white;
}

.location-item__expand-icon {
  @apply w-3 h-3 mr-1 cursor-pointer;
}

.location-item__data {
  @apply flex h-6 items-center whitespace-no-wrap;
}

.location-item__name {
  @apply font-bold ml-1 mr-2;
}

.location-item__context-actions {
  @apply ml-auto mr-1 flex items-center;
}

.location-item :deep(.highlighted) {
  background-color: yellow;
}

.location-item__children {
  @apply pb-4;
}

.location-item__children--regular {
  @apply pb-0 pl-4;
}
</style>
