import { TREE_MODE_L2 } from '@/utils/constants/location-tree';

export const locationMapper = function ({ l1Data = {}, l2Data = {}, ...rest }, mode) {
    const mappedLocation = { ...rest };
    const name = mode === TREE_MODE_L2 ? l2Data.name : l1Data.name;
    const { structureType, type } = l1Data;
    const { businessPurpose } = l2Data;

    if (name) {
        mappedLocation['name'] = name;
    }

    if (structureType) {
        mappedLocation['structureType'] = structureType;
    }

    if (type) {
        mappedLocation['type'] = type;
    }

    if (businessPurpose) {
        mappedLocation['businessPurpose'] = businessPurpose;
    }

    return mappedLocation;
};
