import ConfirmationMixin from '@/mixins/ConfirmationMixin';

export default {
    mixins: [ConfirmationMixin],

    methods: {
        handleBeforeUnload(event) {
            if (!this.isUnsaved()) {
                return;
            }

            event.preventDefault();
            event.returnValue = '';
        },
    },

    mounted() {
        window.addEventListener('beforeunload', this.handleBeforeUnload);
    },

    beforeUnmount() {
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    },

    async beforeRouteLeave(to, from, next) {
        if (!this.isUnsaved()) {
            next();
            return;
        }

        const result = await this.requestConfirmation({
            confirmationMessage: 'are you sure you want to leave without saving changes?',
            confirmBtnText: 'leave',
            cancelBtnText: 'cancel',
            confirmationType: 'warning',
        });
        next(result);
    },
};
