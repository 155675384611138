<template>
    <div
        class="flex h-full overflow-auto"
        :class="reverseOrder ? 'flex-col-reverse' : 'flex-col'"
    >
        <InfiniteScrollWrapper
            ref="messagesBlock"
            class="flex flex-grow overflow-auto pr-1"
            :class="!reverseOrder ? 'flex-col-reverse' : 'flex-col'"
            :loading="loading"
            @reachedTop="handleTopReach"
            @reachedBottom="handleBottomReach"
        >
            <template v-for="message in messages">
                <slot
                    name="message"
                    v-bind="{ message }"
                >
                    <Message
                        :key="message.id"
                        :message="message"
                        :editable="isEditable(message)"
                        :deletable="isDeletable(message)"
                    />
                </slot>
            </template>
        </InfiniteScrollWrapper>
        <template v-if="!loading && messages.length === 0">
            <slot name="no-messages-placeholder">
                <div class="placeholder-block">
                    <span class="exclamation-circle">!</span>
                    <span class="placeholder-text">{{ emptyPlaceholderText }}</span>
                </div>
            </slot>
        </template>
        <template v-else-if="!loading && !canRead">
            <slot name="cannot-read-placeholder">
                <div class="placeholder-block">
                    <span class="exclamation-circle">!</span>
                    <span class="placeholder-text">{{ cannotReadPlaceholderText }}</span>
                </div>
            </slot>
        </template>

        <template v-if="!loading && canWrite">
            <slot
                name="message-composer"
                v-bind="{ canWrite, submitMessage }"
            >
                <div
                    class="message-composer relative"
                    :class="reverseOrder && 'mb-8'"
                >
                    <div class="flex">
                        <text-input
                            v-model="newMessageText"
                            placeholder="Type..."
                            class="mr-4"
                            input-class="new-message-text"
                            :disabled="submitting || !canWrite"
                            rows="1"
                            multiline
                        />
                        <button
                            type="button"
                            class="btn-primary"
                            :disabled="!newMessageText || messageError || submitting || !canWrite"
                            @click="submitMessage({ text: newMessageText })"
                        >
                            add
                        </button>
                        <loader
                            :loading="submitting"
                            backdrop
                        />
                    </div>
                    <div
                        v-if="messageError"
                        class="form-hint form-error"
                    >
                        {{ messageError }}
                    </div>
                </div>
            </slot>
        </template>
        <template v-else-if="!loading && !canWrite">
            <slot name="cannot-write-placeholder">
                <div
                    class="placeholder-block"
                    :class="reverseOrder && 'mb-8'"
                >
                    <span class="exclamation-circle">!</span>
                    <span class="placeholder-text">{{ cannotWritePlaceholderText }}</span>
                </div>
            </slot>
        </template>
    </div>
</template>

<script>
import NotifyMixin from '@/mixins/NotifyMixin';
import InfiniteScrollWrapper from '@/components/list/InfiniteScrollWrapper';
import Message from '@/components/chat/Message';
import { MESSAGE_TYPE_NOTE, MESSAGE_TYPE_SIMPLE, NOTE_TYPE_ORDINARY } from '@/utils/constants/chat';
import Loader from '@/components/ui/Loader';
import { mapGetters } from 'vuex';
import ValidatorMixin from '@/components/form/ValidatorMixin';
import TextInput from '@/components/ui/TextInput';
import EventBus from '@/utils/EventBus';

export default {
    components: { Loader, Message, InfiniteScrollWrapper, TextInput },

    mixins: [NotifyMixin, ValidatorMixin],

    props: {
        /**
         * Target chat ID
         */
        id: {
            type: String,
            required: true,
        },

        /**
         * Send private messages or not
         */
        private: {
            type: Boolean,
            default: false,
        },

        /**
         * Messages type to send and read
         */
        messagesType: {
            type: String,
            default: MESSAGE_TYPE_SIMPLE,
        },

        /**
         * Pass true to place input and new messages at the top
         */
        reverseOrder: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            canRead: undefined,
            canWrite: undefined,
            canEditOwn: undefined,
            canDeleteOwn: undefined,
            editLevels: undefined,
            deleteLevels: undefined,
            loading: false,
            submitting: false,
            hasMoreMessages: true,
            messages: [],
            newMessageText: '',
        };
    },

    computed: {
        ...mapGetters({
            currentIdentityId: 'auth/cognitoUserId',
        }),

        cannotWritePlaceholderText() {
            return `you are not allowed to write ${this.messagesType === MESSAGE_TYPE_NOTE ? 'notes' : 'messages'} here`;
        },

        cannotReadPlaceholderText() {
            return `you are not allowed to read ${this.messagesType === MESSAGE_TYPE_NOTE ? 'notes' : 'messages'} here`;
        },

        emptyPlaceholderText() {
            return `no ${this.messagesType === MESSAGE_TYPE_NOTE ? 'notes' : 'messages'} yet`;
        },

        messageError() {
            return this.maxLength(5000)(this.newMessageText);
        },
    },

    async created() {
        try {
            this.loading = true;
            await this.initPermissions();
            await this.loadMessages();
        } catch (error) {
            this.notifyError(error.message);
        } finally {
            this.loading = false;
        }

        EventBus.on('wst-notification', this.processNotifications);
    },

    beforeUnmount() {
        EventBus.off('wst-notification', this.processNotifications);
    },

    methods: {
        async handleTopReach() {
            if (!this.reverseOrder) {
                const messagesSectionElement = this.$refs.messagesBlock.$el;
                const oldScrollHeight = messagesSectionElement.scrollHeight;

                await this.loadNextPage();

                this.$nextTick(() => {
                    messagesSectionElement.scrollTop = messagesSectionElement.scrollHeight - oldScrollHeight;
                });
            }
        },

        async handleBottomReach() {
            if (this.reverseOrder) {
                await this.loadNextPage();
            }
        },

        async loadNextPage() {
            if (this.loading || !this.hasMoreMessages) return;

            try {
                this.loading = true;
                await this.loadMessages();
            } catch (error) {
                this.notifyError(error.message);
            } finally {
                this.loading = false;
            }
        },

        async initPermissions() {
            const { data: chatData } = await this.$chatDataProvider.getOne('chats', { id: this.id });
            const target =
                (this.private ? 'private' : 'public') +
                `/${this.messagesType}/` +
                (this.messagesType === MESSAGE_TYPE_NOTE ? NOTE_TYPE_ORDINARY : '*');
            this.canRead = chatData.permissions.find(p => p.target === target && p.action === 'READ').allowed === true;
            this.canWrite = chatData.permissions.find(p => p.target === target && p.action === 'WRITE').allowed === true;
            this.canEditOwn = chatData.editOwnMsgsAllowed;
            this.canDeleteOwn = chatData.deleteOwnMsgsAllowed;
            this.editLevels = chatData.editLevels;
            this.deleteLevels = chatData.deleteLevels;
        },

        isEditable(message) {
            if (message.deletedAt) {
                return false;
            }

            if (message.author.id.identityId === this.currentIdentityId && this.canEditOwn) {
                return true;
            }

            if (!this.editLevels) {
                return false;
            }

            const entries = Object.entries(this.editLevels);
            if (entries.length === 0) return false;
            for (const [key, value] of entries) {
                if (message.levels?.[key] <= value) return true;
            }

            return false;
        },

        isDeletable(message) {
            if (message.deletedAt) {
                return false;
            }

            if (message.author.id.identityId === this.currentIdentityId && this.canDeleteOwn) {
                return true;
            }

            if (!this.deleteLevels) {
                return false;
            }

            const entries = Object.entries(this.deleteLevels);
            if (entries.length === 0) return false;
            for (const [key, value] of entries) {
                if (message.levels?.[key] <= value) return true;
            }

            return false;
        },

        async loadMessages() {
            const options = {
                id: this.id,
                type: this.messagesType,
                subtype: this.messagesType === MESSAGE_TYPE_NOTE ? NOTE_TYPE_ORDINARY : null,
            };

            if (this.messages?.[this.messages.length - 1]?.id) {
                options.startFrom = this.messages[this.messages.length - 1].id;
            }

            const { data: messages } = await this.$chatDataProvider.getList('messages', options);

            this.hasMoreMessages = messages.length > 0;

            this.messages = [...this.messages, ...messages];
        },

        async submitMessage(body) {
            if (!this.canWrite) return;

            try {
                this.submitting = true;
                const { data } = await this.$chatDataProvider.create('messages', {
                    id: this.id,
                    data: {
                        body,
                        type: this.messagesType,
                        subtype: this.messagesType === MESSAGE_TYPE_NOTE ? NOTE_TYPE_ORDINARY : null,
                        private: this.private,
                    },
                });
                this.messages.unshift(data);
                this.newMessageText = '';
            } catch (error) {
                this.notifyError(error.message);
            } finally {
                this.submitting = false;
            }
        },

        processNotifications({ channel, body: message }) {
            switch (channel) {
            case 'CHAT/new_chat_message':
                // different chat
                if (message.chatId !== this.id) return;
                // my message (already displayed)
                if (message.author.id.identityId === this.currentIdentityId) return;
                // different kind
                if (message.type !== this.messagesType) return;

                this.messages = [message, ...this.messages];
                break;

            case 'CHAT/update_chat_message':
                // different chat
                if (message.chatId !== this.id) return;

                // eslint-disable-next-line no-case-declarations
                const targetMessageIndex = this.messages.findIndex(({ id }) => id === message.id);
                if (targetMessageIndex >= 0) {
                    this.messages[targetMessageIndex] = { ...message };
                }
                break;
            }
        },
    },
};
</script>

<style scoped>
.message-composer {
    button {
        min-width: 5.5rem;
    }

    &:deep(.new-message-text) {
        min-height: 2.5rem;
    }
}

.placeholder-block {
    @apply flex items-center justify-center bg-blue-100 border border-blue-300 text-blue-700 py-6;

    .exclamation-circle {
        @apply flex items-center justify-center w-4 h-4 border-2 border-blue-700 rounded-full text-xs font-frank font-600 mr-2;
    }

    .placeholder-text {
        @apply font-frank font-medium text-sm;
    }
}
</style>
