<template>
  <FinalForm
      ref="form"
      :initial-values="initialValues"
      :submit="handleSubmit"
      @change.self="handleFormChange"
  >
    <template v-slot="props">
      <div class="mb-8">
        <div class="text-black text-sm font-frank font-bold pb-4">add new child location</div>
        <div class="flex flex-wrap border bg-graphite-100 text-black px-5 py-2 mb-8">
          <div
              v-for="(location, index) of locationsChain"
              :key="location.id"
              @click="scrollToLocation(location.id)"
              class="cursor-pointer py-3"
              :class="index === locationsChain.length - 1 ? 'mr-6' : 'mr-8'"
          >
            <div class="font-semibold text-xs lowercase mb-2">
              {{ location.l1Data.type.replaceAll('_', ' ') }}
            </div>
            <div class="font-bold text-2sm">
              {{ location.l2Data.name }}
            </div>
          </div>
          <div class="new-child bg-active-100 p-3 cursor-pointer" @click="scrollToLocation(tempLocation.id)">
            <div class="font-semibold text-xs mb-2">
              new child
            </div>
            <div class="font-bold text-2sm">
              {{ props.values.name || '-' }}
            </div>
          </div>
        </div>
      </div>
      <form ref="form" @submit="props.handleSubmit" class="grid grid-cols-2 gap-6">
        <div class="form-row">
          <TextField name="name" label="name*" :validate="required"/>
        </div>
        <div class="form-row row-span-2">
          <TextField name="description" label="description*" :validate="required" multiline class="description-field"/>
        </div>
        <div class="form-row">
          <AmountField
            name="areaFt"
            label="square footage*"
            :precision="0"
            :compute-width="false"
            :validate="minValue(1)"
            text-class="text-left"
          />
        </div>
        <div class="form-row">
          <SelectInput
              name="structureType"
              label="structure type*"
              :options="structureTypeOptions"
              :searchable="false"
              open-direction="bottom"
              no-clear
              option-key="id"
              option-label="name"
              :validate="required"
          />
        </div>
        <div class="form-row">
          <BusinessPurposeSelectInput
              name="businessPurpose"
              label="business purpose"
              :options="purposeOptions"
          />
        </div>
        <loader :loading="loading" backdrop/>
      </form>
    </template>
  </FinalForm>
</template>

<script>
import { mapGetters} from "vuex";
import {FinalForm} from 'vue-final-form';
import TextField from "@/components/form/TextField";
import ValidatorMixin from "@/components/form/ValidatorMixin";
import SelectInput from "@/components/form/SelectInput";
import { DEFAULT_BUSINESS_PURPOSE_OPTIONS } from "@/utils/constants/cbr";
import { ROOT_LOCATION_ID } from "@/utils/constants/location-tree";
import NotifyMixin from "@/mixins/NotifyMixin";
import Loader from "@/components/ui/Loader";
import {createNamespacedHelpers} from "vuex";
import BusinessPurposeSelectInput from "@/components/cbr/designation/BusinessPurposeSelectInput";
import {v4} from "uuid";
import AmountField from "@/components/form/AmountField";

const { mapActions } = createNamespacedHelpers('location_tree');

export default {
  name: 'LocationCreate',

  components: {AmountField, BusinessPurposeSelectInput, Loader, SelectInput, TextField, FinalForm},

  mixins: [ValidatorMixin, NotifyMixin],

  props: {
    parentId: {
      type: String,
      required: true,
    },

    purposeOptions: {
      type: Array,
      default: () => DEFAULT_BUSINESS_PURPOSE_OPTIONS,
    },
  },

  emits: ['created'],

  data() {
    return {
      structureTypeOptions: [],
      loading: false,
      tempLocation: {},
      initialValues: {
        name: '',
        description: '',
        areaFt: '',
        structureType: null,
        businessPurpose: null,
      },
      formValues: {},
    };
  },

  computed: {
    ...mapGetters({
      locationTree: 'location_tree/locationTree',
    }),

    locationsChain() {
      const chain = [];
      const func = (location) => {
        if (location.id === ROOT_LOCATION_ID) return chain;

        chain.unshift(location);
        return func(this.locationTree[location.parentId]);
      };

      return func(this.locationTree[this.parentId]);
    },
  },

  methods: {
    ...mapActions([
      'addSingleLocation',
      'updateSingleLocation',
      'deleteCommunityLocation',
    ]),

    handleFormChange(state) {
      this.formValues = state.values;
    },

    save() {
      this.$refs.form.requestSubmit();
    },

    handleSubmit(values) {
      this.loading = true;
      this.$cbrDataProvider.create('locations', {
        data: {
          ...values,
          structureType: {id: values.structureType},
          parentId: this.parentId,
        }
      }).then(location => {
        this.$emit('created', {...location, parentId: this.parentId, children: []});
        this.deleteCommunityLocation(this.tempLocation);
      }).catch(error => {
        this.notifyError(error.message);
      }).finally(() => {
        this.loading = false;
      });
    },

    scrollToLocation(id) {
      const element = document.querySelector('.location-item-' + id);
      if (element) {
        element.scrollIntoView({behavior: 'smooth'});
      }
    },
  },

  created() {
    this.formValues = this.initialValues;
  },

  mounted() {
    this.loading = true;
    this.$cbrDataProvider.getChildStructureTypes('locations', {parentId: this.parentId})
        .then(response => {
          this.structureTypeOptions = response;
        })
        .catch(error => {
          this.notifyError(error.message);
        })
        .finally(() => {
          this.loading = false;
        });

    this.tempLocation = {
      isTemp: true,
      id: v4(),
      parentId: this.parentId,
      l1Data: {
        name: '<name>',
        structureType: {
          name: '<structure type>',
        },
      },
      l2Data: {
        name: '<name>',
        businessPurpose: null,
      },
      children: [],
    };
    this.addSingleLocation(this.tempLocation);
    this.scrollToLocation(this.tempLocation.id);

    this.$watch(
        () => this.formValues.name,
        (value) => {
          const nameToSet = value || '<name>';
          this.tempLocation.l1Data.name = nameToSet;
          this.tempLocation.l2Data.name = nameToSet;
          this.updateSingleLocation({...this.tempLocation});
        },
    );

    this.$watch(
        () => this.formValues.businessPurpose,
        (value) => {
          this.tempLocation.l2Data.businessPurpose = value;
          this.updateSingleLocation({...this.tempLocation});
        },
    );

    this.$watch(
        () => this.formValues.structureType,
        (value) => {
          this.tempLocation.l1Data.structureType.name = value || '<structure type>';
          this.updateSingleLocation({...this.tempLocation});
        },
    );
  },

  beforeUnmount() {
    this.deleteCommunityLocation(this.tempLocation);
    this.scrollToLocation(this.parentId);
  }
}
</script>

<style scoped>
.form-row {
  @apply mb-0;
}
.description-field {
  @apply flex flex-col;
}
.description-field:deep(textarea)  {
  @apply flex-grow;
}

.new-child {
  min-width: 144px;
}
</style>
