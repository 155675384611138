<template>
  <div class="flex flex-col justify-between h-full">
    <div class="flex flex-grow min-h-0">
      <div class="flex flex-col flex-shrink-0 w-1/3 bg-gray-100 border text-xs overflow-auto h-full">
        <LocationTreePanel
            class="flex-grow"
            @selectLocation="location => selectedLocation = location"
            @addChildLocation="showChildCreationForm = true"
            @deleteLocation="deleteLocation"
            show-tree-modes
            eager-mode
            :with-add-child="hasPermission('LC')"
            :with-delete="hasPermission('LD')"
            :creating-location="showChildCreationForm"
            :force-tree-mode="TREE_MODE_L2"
            :dataProvider="$cbrDataProvider"
            :location-mapper="locationMapper"
            :additionalRequestParams="{fullTree: true}"
        />
      </div>
      <div v-if="selectedLocation" class="flex flex-col w-2/3">
        <div class="flex flex-grow">
          <div
              v-if="showChildCreationForm"
              class="flex flex-col flex-shrink-0 pl-8 overflow-y-auto w-full"
          >
            <LocationCreate
                ref="form"
                :parent-id="selectedLocation.id"
                :purpose-options="businessPurposeOptions"
                @created="addNewLocation"
            />
          </div>
          <template v-else>
            <div class="flex flex-col flex-shrink-0 w-1/2 border-r px-8 overflow-y-auto">
              <div class="text-black text-sm font-frank font-bold pb-4">physical data</div>
              <LocationFormL1 :location="selectedLocation"/>
            </div>
            <div class="flex flex-col flex-shrink-0 w-1/2 pl-8 overflow-y-auto">
              <div class="text-black text-sm font-frank font-bold pb-4">business data</div>
              <LocationFormL2
                  ref="form"
                  :key="selectedLocation.id"
                  :location="selectedLocation"
                  :editable="hasPermission('LU')"
                  with-business-purpose
                  :purpose-options="businessPurposeOptions"
                  @update="updateLocation"
                  @batchUpdate="batchUpdateBusinessPurpose"
              />
            </div>
          </template>
        </div>
        <div class="flex pt-4 pl-8">
          <button
              type="button"
              class="btn-primary btn-transparent mr-4"
              @click="cancel"
          >
            cancel
          </button>
          <div class="flex-grow"/>
          <button
              v-if="hasPermission('LU')"
              type="button"
              class="btn-primary btn-solid"
              @click="save"
          >
            save
          </button>
        </div>
      </div>
    </div>

    <loader :loading="loading" backdrop/>
  </div>
</template>

<script>
import { mapGetters, createNamespacedHelpers } from 'vuex';
import ModalNavigation from '@/mixins/ModalNavigation';
import NotifyMixin from '@/mixins/NotifyMixin';
import LocationFormL1 from '@/components/cbr/designation/LocationFormL1';
import LocationFormL2 from '@/components/cbr/designation/LocationFormL2';
import LocationTreePanel from "@/components/ui/location-tree/LocationTreePanel";
import LocationCreate from "@/components/cbr/designation/LocationCreate";
import Loader from "@/components/ui/Loader";
import ConfirmationMixin from "@/mixins/ConfirmationMixin";
import { TREE_MODE_L2 } from "@/utils/constants/location-tree";
import {locationMapper} from "@/utils/cbr";

const { mapActions } = createNamespacedHelpers('location_tree');

export default {
  components: {Loader, LocationCreate, LocationFormL1, LocationFormL2, LocationTreePanel},

  mixins: [ModalNavigation, NotifyMixin, ConfirmationMixin],

  data() {
    return {
      TREE_MODE_L2,
      selectedLocation: null,
      businessPurposeOptions: [],
      showChildCreationForm: false,
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      locationTree: 'location_tree/locationTree',
      hasPermission: 'cbr/hasPermission',
    }),
  },

  watch: {
    selectedLocation() {
      this.showChildCreationForm = false;
    },
  },

  methods: {
    ...mapActions([
      'addCommunityLocations',
      'addSingleLocation',
      'deleteCommunityLocation',
    ]),

    locationMapper,

    cancel() {
      this.selectedLocation = null;
      this.showChildCreationForm = false;
    },

    save() {
      this.$refs.form.save();
    },

    batchUpdateBusinessPurpose({sameAsId, businessPurpose}) {
      const locationsToUpdate = Object.values(this.locationTree)
          .filter(location => location?.l1Data?.type === this.locationTree[sameAsId].l1Data.type);

      this.addCommunityLocations(locationsToUpdate.map(location => ({
        ...location,
        l2Data: {
          ...location.l2Data,
          businessPurpose,
        },
      })));
    },

    updateLocation(result) {
      this.addCommunityLocations([result]);
    },

    addNewLocation(location) {
      this.addSingleLocation(location);
      this.selectedLocation = location;
      this.showChildCreationForm = false;
    },


    async deleteLocation() {
      if (await this.requestConfirmation({
        confirmationType: 'warning',
        confirmationMessage: 'are you sure you want to delete this item?',
        confirmBtnText: 'yes, delete',
        cancelBtnText: 'no, go back',
      })) {
        try {
          this.loading = true;
          await this.$cbrDataProvider.delete('locations', {id: this.selectedLocation.id});
          this.deleteCommunityLocation(this.selectedLocation);
          this.selectedLocation = null;
        } catch (error) {
          this.notifyError(error.message);
        } finally {
          this.loading = false;
        }
      }
    },
  },

  mounted() {
    this.$cbrDataProvider.getList('businessPurposes').then(response => {
      this.businessPurposeOptions = [
        {key: null, value: 'Unspecified'},
        ...response.reduce((acc, cv) => {
          return [
            ...acc,
            {$isSeparator: true},
            {key: cv.code, value: cv.name, $isDisabled: true},
            ...cv.businessPurposes.map(({code, name}) => ({
              key: code,
              value: name,
            })),
          ];
        }, []),
      ];
    }).catch(error => {
      this.notifyError(error.message);
    });
  }
};
</script>
