<template>
  <FinalForm :initial-values="location.l2Data" :submit="handleSubmit">
    <template v-slot="props">
      <form ref="form" @submit="props.handleSubmit">
        <div class="form-row">
          <TextField
            name="name"
            label="name"
            :edit-mode="editable"
          />
        </div>
        <div class="form-row">
          <TextField
            name="description"
            label="description"
            :edit-mode="editable"
          />
        </div>
        <template v-if="withBusinessPurpose">
          <div class="form-row">
            <BusinessPurposeSelectInput
                name="businessPurpose"
                label="business purpose"
                :options="purposeOptions"
                :edit-mode="editable"
            />
          </div>
          <button
              v-if="editable"
              type="button"
              class="btn-primary mt-4"
              @click="applyPurposeForAll"
          >
            apply for all of such type
          </button>
        </template>
      </form>
      <loader :loading="loading" backdrop/>
    </template>
  </FinalForm>
</template>

<script>
import {FinalForm} from 'vue-final-form';
import TextField from '@/components/form/TextField';
import ValidatorMixin from '@/components/form/ValidatorMixin';
import NotifyMixin from '@/mixins/NotifyMixin';
import {DEFAULT_BUSINESS_PURPOSE_OPTIONS} from "@/utils/constants/cbr";
import Loader from "@/components/ui/Loader";
import BusinessPurposeSelectInput from "@/components/cbr/designation/BusinessPurposeSelectInput";

export default {
  components: {BusinessPurposeSelectInput, Loader, TextField, FinalForm},

  mixins: [ValidatorMixin, NotifyMixin],

  props: {
    location: {
      type: Object,
      required: true,
    },

    withBusinessPurpose: {
      type: Boolean,
      default: false,
    },

    purposeOptions: {
      type: Array,
      default: () => DEFAULT_BUSINESS_PURPOSE_OPTIONS,
    },

    editable: {
      type: Boolean,
      default: true,
    },
  },

  emits: [
    'update',
    'batchUpdate',
  ],

  data() {
    return {
      forAll: false,
      loading: false,
    };
  },

  methods: {
    save() {
      this.forAll = false;
      this.$refs.form.requestSubmit();
    },

    applyPurposeForAll() {
      this.forAll = true;
      this.$refs.form.requestSubmit();
    },

    async handleSubmit(values) {
      try {
        this.loading = true;

        if (this.forAll) {
          const {businessPurpose} = values;
          const updatedCount = await this.$cbrDataProvider.applyPurposeForAll('locations', {
            id: this.location.id,
            data: businessPurpose ? `"${businessPurpose}"` : businessPurpose,
          });
          this.notifySuccess(`${updatedCount} locations updated`);
          this.$emit('batchUpdate', {sameAsId: this.location.id, businessPurpose});
        } else {
          const result = await this.$cbrDataProvider.update('locations', {
            id: this.location.id,
            data: {
              l2Data: {
                ...values,
              },
            },
          })

          this.$emit('update', {
            ...result,
            parentId: this.location.parentId,
            children: this.location.children,
          });
        }
      } catch (e) {
        this.notifyError(e.message);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
