<template>
  <div class="flex flex-col h-full">
    <List
        :key="community.id"
        :columns="columns"
        :items="items"
        @rowClick="item => $router.push({name: 'cbr.living.units', params: {buildingId: item.id}})"
    >
      <template v-slot:column:structureType="{value}">
        {{ $t(`cbr.structure_types.${value}`) === `cbr.structure_types.${value}` ? value : $t(`cbr.structure_types.${value}`) }}
      </template>
    </List>

    <loader :loading="loading" backdrop/>
  </div>
</template>

<script>
import AuthMixin from "@/components/auth/AuthMixin";
import ModalNavigation from "@/mixins/ModalNavigation";
import List from "@/components/list/List";
import Loader from "@/components/ui/Loader";
import NotifyMixin from "@/mixins/NotifyMixin";

const columns = [
  {
    name: 'name',
    title: 'building name',
    class: 'w-3/12',
  },
  {
    name: 'stats.totalUnits',
    title: 'total units',
    class: 'w-1/12',
  },
  {
    name: 'stats.leasedUnits',
    title: 'leased units',
    class: 'w-1/12',
  },
  {
    name: 'stats.vacantUnits',
    title: 'vacant units',
    class: 'w-1/12',
  },
  {
    name: 'stats.ntvUnits',
    title: 'units on notice-to-vacate',
    class: 'w-2/12',
  },
  {
    name: 'stats.futureLeaseUnits',
    title: 'units with future lease',
    class: 'w-2/12',
  },
  {
    name: 'structureType',
    title: 'structure type',
    class: 'w-2/12',
  },
];

export default {
  mixins: [AuthMixin, ModalNavigation, NotifyMixin],

  components: {Loader, List},

  props: {
    columns: {
      type: Array,
      default: () => columns,
    },
  },

  data() {
    return {
      loading: false,
      items: [],
    };
  },

  mounted() {
    this.loading = true;
    this.$cbrDataProvider.getList('livingBuildings').then(({items}) => {
      this.items = items.map(({value}) => value);
    }).catch(error => {
      this.notifyError(error.message);
    }).finally(() => {
      this.loading = false;
    });
  }
};
</script>
