<template>
  <div class="h-full">
    <FinalForm v-if="initialized" :initial-values="initialValues" :submit="handleSubmit">
      <template v-slot="props">
        <form ref="form" @submit="props.handleSubmit">
          <div class="form-row">
            <SelectInput
                class="select-input"
                name="unit"
                label="unit"
                :options="unitOptions"
                :local-filtering-function="optionsFilteringFunction"
            />
          </div>
        </form>
      </template>
    </FinalForm>
    <loader :loading="loading" backdrop/>
  </div>
</template>

<script>
import {FinalForm} from 'vue-final-form';
import SelectInput from "@/components/form/SelectInput";
import NotifyMixin from "@/mixins/NotifyMixin";
import Loader from "@/components/ui/Loader";

export default {
  name: "UnitAssignment",

  components: {Loader, SelectInput, FinalForm},

  mixins: [NotifyMixin],

  props: {
    locationId: {
      type: String,
      required: true,
    },

    isVisible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      parkingSpace: null,
      initialized: false,
      loading: false,
      unitOptions: [],
      initialValues: {
        unit: null,
      },
    };
  },

  watch: {
    isVisible: {
      handler: function (isVisible) {
        if (!this.initialized && isVisible) {
          this.initialize();
        }
      },
      immediate: true,
    },
  },

  methods: {
    initialize() {
      this.loading = true;
      Promise.all([
        this.$cbrDataProvider.getOne('parkingSpaces', {id: this.locationId}).then(({linkedTo}) => {
          if (linkedTo) {
            this.initialValues = {unit: linkedTo.unitId};
          }
        }),
        this.$cbrDataProvider.getAll('livingUnits').then(response => {
          this.unitOptions = response.reduce((acc, cv) => {
            return [
              ...acc,
              {
                key: cv.building.id,
                value: cv.building.name,
                $isDisabled: true,
                $isSeparator: true,
              },
              ...cv.units.map(({id, name}) => ({key: id, value: name})),
            ];
          }, []);
        }),
      ]).then(() => {
        this.initialized = true;
      }).catch(error => {
        this.notifyError(error.message);
      }).finally(() => {
        this.loading = false;
      });
    },

    save() {
      this.$refs.form.requestSubmit();
    },

    handleSubmit({unit}) {
      this.loading = true;

      this.$cbrDataProvider.update('parkingSpaces', {
        id: this.locationId,
        data: {
          linkedTo: unit ? { unitId: unit } : null,
        },
      }).then(() => {
        this.notifySuccess(`Parking space has been successfully ${unit ? '' : 'un'}assigned`);
      }).catch(error => {
        this.notifyError(error.message);
      }).finally(() => {
        this.loading = false;
      });
    },

    optionsFilteringFunction(searchQuery, options) {
      return options.filter(option => {
          return option.$isDisabled || option.$isSeparator || option.value.toLowerCase().includes(searchQuery.toLowerCase());
        }).filter((option, index, options) => {
          const nextOption = options[index + 1];
          return !option.$isSeparator || (nextOption && !nextOption.$isSeparator);
        });
    },
  },
}
</script>

<style scoped>
.select-input:deep(.dropdown__item.dropdown__item--disabled .dropdown__checkbox) {
  @apply hidden;
}
</style>
