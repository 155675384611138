export const MESSAGE_TYPE_NOTE = 'NOTE';
export const MESSAGE_TYPE_SIMPLE = 'SIMPLE';

export const NOTE_TYPE_ORDINARY = 'ORDINARY';
export const NOTE_TYPE_COST_ALLOCATION = 'COST_ALLOCATION';

export const USER_TYPE_QUEXT = 'QUEXT';

export const CHAT_ACTION_READ = 'READ';
export const CHAT_ACTION_WRITE = 'WRITE';

export const MESSAGE_STATUS_RECEIVED = 'RECEIVED';
export const MESSAGE_STATUS_PENDING = 'PENDING';
export const MESSAGE_STATUS_FAILED = 'FAILED';
