<template>
  <div>
    <div class="form-row">
      <div class="form-col">
        <label>name</label>
        <div>{{location.l1Data.name || '-'}}</div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-col">
        <label>description</label>
        <div>{{location.l1Data.description || '-'}}</div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-col">
        <label>type</label>
        <div>{{location.l1Data.type || '-'}}</div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-col">
        <label>structure type</label>
        <div>{{location.l1Data.structureType.name || '-'}}</div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-col">
        <label>area</label>
        <div>{{location.l1Data.area || '-'}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    location: {
      type: Object,
      required: true,
    },
  },
};
</script>
