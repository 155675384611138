import { createNamespacedHelpers } from 'vuex';
import NotifyMixin from '@/mixins/NotifyMixin';
import { isUrlImage, openFileDialog } from '@/utils/files';
import Axios from 'axios';
import { v4 } from 'uuid';

const { mapGetters, mapActions } = createNamespacedHelpers('attachments');

const MAX_SIZE = 10 * 1024 * 1024;

export default {
    mixins: [NotifyMixin],

    computed: {
        ...mapGetters(['attachments', 'attachmentParams', 'attachingPossible', 'tooManyAttachments']),
    },

    methods: {
        ...mapActions({
            _setAttachmentParams: 'setAttachmentParams',
            _initAttachments: 'initAttachments',
            _addAttachment: 'addAttachment',
            _updateAttachment: 'updateAttachment',
        }),

        async initAttachments(items = [], params = {}) {
            const { maxItems = 0, ownerService = null, dataProvider = this.$attachmentsDataProvider, resource = 'attachments' } = params;

            this._setAttachmentParams({
                maxItems,
                ownerService,
                urlFetcher: (...args) => dataProvider.create(resource, ...args),
                ownerRoute: this.$route.name,
            });

            try {
                const storedItems =
                    items.length === 0
                        ? []
                        : await this.$attachmentsDataProvider.getList('attachments', { ids: items.map(item => item.id) });

                const availableItems = new Set(this.attachments.map(item => item.id));
                const itemsToLoad = storedItems.filter(item => !availableItems.has(item.id));

                this._initAttachments([
                    ...itemsToLoad.map(item => ({
                        ...item,
                        isImage: false,
                        stored: true,
                        inProgress: true,
                    })),
                    ...this.attachments,
                ]);

                const promises = itemsToLoad.map(item => {
                    return Axios.get(item.url, { responseType: 'blob' }).then(({ data }) => {
                        const objectUrl = URL.createObjectURL(data);
                        return isUrlImage(objectUrl).then(isImage => {
                            return Promise.resolve({
                                id: item.id,
                                values: {
                                    data,
                                    objectUrl: null,
                                    previewUrl: objectUrl,
                                    isImage,
                                    inProgress: false,
                                },
                            });
                        });
                    });
                });

                await Promise.all(promises).then(updatePayloads => {
                    // Stop execution because user has left the page
                    if (!this.attachmentParams.ownerRoute) {
                        return;
                    }

                    for (const payload of updatePayloads) {
                        this._updateAttachment(payload);
                    }
                });
            } catch (e) {
                this.notifyError(e.message);
            }
        },

        attachFile(options) {
            if (!this.attachingPossible) {
                return;
            }

            openFileDialog(async file => {
                if (file.size > MAX_SIZE) {
                    this.notifyError('file is too big');
                    return;
                }

                const objectUrl = URL.createObjectURL(file);
                const isImage = await isUrlImage(objectUrl);

                this._addAttachment({
                    id: v4(),
                    stored: false,
                    filename: file.name,
                    contentType: file.type,
                    size: file.size,
                    objectUrl: objectUrl,
                    previewUrl: objectUrl,
                    isImage: isImage,
                    inProgress: false,
                    error: null,
                    file,
                });
            }, options);
        },

        async attachFileManagerItem(item) {
            if (parseInt(item.file_size) > MAX_SIZE) {
                this.notifyError('file is too big');
                return;
            }

            try {
                const { data } = await Axios.get(item.file_url, { responseType: 'blob' });

                const file = new File([data], item.original_name, { type: item.file_type });

                const objectUrl = URL.createObjectURL(file);
                const isImage = await isUrlImage(objectUrl);

                this._addAttachment({
                    id: v4(),
                    stored: false,
                    filename: file.name,
                    contentType: file.type,
                    size: file.size,
                    objectUrl: objectUrl,
                    previewUrl: objectUrl,
                    isImage: isImage,
                    inProgress: false,
                    error: null,
                    file,
                });
            } catch (error) {
                this.notifyError(error.message);
            }
        },

        async uploadFiles() {
            const toUpload = this.attachments.filter(attach => !attach.stored);

            if (!(await this._uploadBatch(toUpload))) {
                throw new Error('Failed to upload attachments');
            }
        },

        async _uploadBatch(toUpload) {
            if (toUpload.length === 0) {
                return true;
            }

            toUpload.forEach(({ id }) => {
                this._updateAttachment({
                    id,
                    values: {
                        inProgress: true,
                        error: null,
                    },
                });
            });

            let resources = [];
            try {
                resources = await this._prepareUploadUrls(toUpload);
            } catch (error) {
                toUpload.forEach(({ id }) => {
                    this._updateAttachment({
                        id,
                        values: {
                            inProgress: false,
                            error,
                        },
                    });
                });
                return false;
            }

            return (
                await Promise.all(
                    toUpload.map((item, index) =>
                        this._uploadFileToS3(item.file, resources[index].uploadUrl)
                            .then(() => {
                                this._updateAttachment({
                                    id: item.id,
                                    values: {
                                        id: resources[index].id,
                                        inProgress: false,
                                        stored: true,
                                    },
                                });
                                return true;
                            })
                            .catch(error => {
                                this._updateAttachment({
                                    id: item.id,
                                    values: {
                                        inProgress: false,
                                        error,
                                    },
                                });
                                return false;
                            })
                    )
                )
            ).reduce((acc, cv) => acc && cv, true);
        },

        _prepareUploadUrls(items) {
            const { urlFetcher, ownerService } = this.attachmentParams;

            return urlFetcher({
                data: items.map(({ file }) => ({
                    filename: file.name,
                    contentType: file.type,
                    size: file.size,
                    serviceId: ownerService,
                })),
            });
        },

        _uploadFileToS3(file, uploadUrl) {
            return Axios.put(uploadUrl, file, {
                headers: {
                    'content-type': file.type,
                    'x-amz-tagging': 'QUEXT_CONFIRMED=false',
                },
            });
        },
    },
};
