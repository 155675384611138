<template>
  <span
    class="location-indicator"
    :class="[colorClass, `location-indicator--size-${size}`]"
  />
</template>

<script>
import {
  BUSINESS_PURPOSE_COMMON_AREA,
  BUSINESS_PURPOSE_PARKING,
  BUSINESS_PURPOSE_SHARED_AREA,
  BUSINESS_PURPOSE_STAFF_ONLY,
  BUSINESS_PURPOSE_UNIT,
  BUSINESS_PURPOSE_UNIT_AREA,
} from '@/utils/constants/cbr';

export default {
  props: {
    businessPurpose: {
      type: String,
    },
    size: {
      type: String,
      default: 'small',
      validator: (value) => ['small', 'large'].includes(value),
    },
  },

  computed: {
    colorClass() {
      switch (this.businessPurpose) {
        case BUSINESS_PURPOSE_UNIT:
        case BUSINESS_PURPOSE_UNIT_AREA:
        case BUSINESS_PURPOSE_SHARED_AREA:
          return 'bg-cbr-living';
        case BUSINESS_PURPOSE_COMMON_AREA:
        case BUSINESS_PURPOSE_PARKING:
          return 'bg-cbr-common';
        case BUSINESS_PURPOSE_STAFF_ONLY:
          return 'bg-cbr-maintenance';
        default:
          return 'bg-gray-300';
      }
    },
  },
};
</script>

<style scoped>
.location-indicator {
  @apply rounded inline-block;
}

.location-indicator--size-small {
  width: 6px;
  height: 6px;
}
.location-indicator--size-large {
  @apply w-2 h-2;
}
</style>
