<template>
  <div class="h-1 bg-graphite-500 overflow-hidden rounded">
    <div class="h-full bg-active-500" :style="{width: `${progress}%`}"></div>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * Progress value (1 - 100)
     */
    progress: {
      type: Number,
      required: true,
      validator: value => value >= 0 && value <= 100,
    },
  },
}
</script>
