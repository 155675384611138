<template>
  <div class="h-full">
    <FinalForm
        v-if="initialized"
        class="h-full"
        ref="form"
        :submit="handleSubmit"
        :initial-values="initialValues"
    >
      <template v-slot="props">
        <form class="h-full" @submit="props.handleSubmit">
          <div class="grid grid-cols-2 gap-6 pb-16">
            <div>
              <TextField
                  name="name"
                  label="name *"
                  :validate="composeValidators(required, maxLength(75))"
                  :edit-mode="editMode"
              />
              <TextField
                  name="virtualTourUrl"
                  label="virtual tour url"
                  :validate="safeUrl"
                  :edit-mode="editMode"
              />
              <FinalField name="image" :validate="notEmpty">
                <template v-slot="bodyProps">
                  <div class="form-col">
                    <div class="label">
                      attachment *
                    </div>
                    <template v-if="editMode">
                      <FileManager
                          class="file-manager"
                          :class="{withImage: bodyProps.value && bodyProps.value[0]}"
                          :value="[bodyProps.value]"
                          @input="value => bodyProps.change(value[0])"
                          :max-nb-of-files="1"
                          :library="libraryFiles"
                          :editable="false"
                      />
                      <span
                          v-if="(bodyProps.meta.error && bodyProps.meta.touched) || (bodyProps.meta.submitError && !bodyProps.meta.dirtySinceLastSubmit)"
                          class="form-hint form-error"
                      >
                        {{ (bodyProps.meta.error || bodyProps.meta.submitError) }}
                      </span>
                    </template>
                    <template v-else>
                      <ManagedImage
                          v-if="bodyProps.value"
                          :id="bodyProps.value"
                          thumb
                      />
                    </template>
                  </div>
                </template>
              </FinalField>
            </div>
            <div>
              <TextField
                name="description"
                label="marketing description *"
                :validate="composeValidators(required, minLength(5), maxLength(500))"
                multiline
                :edit-mode="editMode"
              />
              <div v-if="initialFloorPlan" class="form-col">
                <div class="label">
                  assigned to {{initialFloorPlan.unitTypes.length}} unit types:
                </div>
                <div class="unit-types">
                  <Tag
                      v-for="unitType in initialFloorPlan.unitTypes"
                      :key="unitType.id"
                      :text="unitType.name"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer" id="modal-footer">
            <div>
              <button type="button" class="btn-primary btn-transparent" @click="close">
                cancel
              </button>
            </div>
            <div class="flex" v-if="editMode">
              <button type="submit" class="btn-primary btn-solid">
                save
              </button>
            </div>
          </div>
        </form>
      </template>
    </FinalForm>
    <loader :loading="loading" backdrop/>
  </div>
</template>

<script>
import ModalNavigation from "@/mixins/ModalNavigation";
import Loader from "@/components/ui/Loader";
import {FinalForm, FinalField} from 'vue-final-form';
import TextField from "@/components/form/TextField";
import ValidatorMixin from "@/components/form/ValidatorMixin";
import FileManager from "@/components/ui/filemanager/FileManager";
import {mapActions, mapGetters} from "vuex";
import EventBus from "@/utils/EventBus";
import ActionsMixin from "@/mixins/ActionsMixin";
import NotifyMixin from "@/mixins/NotifyMixin";
import AttachmentMixin from "@/mixins/AttachmentMixin";
import UnsavedNotifierMixin from '@/mixins/UnsavedNotifierMixin';
import Tag from "@/components/ui/Tag";
import ManagedImage from "@/components/ui/ManagedImage";

export default {
  components: {ManagedImage, Tag, FileManager, TextField, Loader, FinalForm, FinalField},

  mixins: [NotifyMixin, ValidatorMixin, ActionsMixin, AttachmentMixin, UnsavedNotifierMixin, ModalNavigation],

  data() {
    return {
      loading: false,
      initialized: false,
      initialValues: {
        name: null,
        virtualTourUrl: null,
        description: null,
        image: undefined,
      },
      initialFloorPlan: null,
    };
  },

  computed: {
    ...mapGetters({
      libraryFiles: 'files/getAllLibraryFiles',
      hasCbrPermission: 'cbr/hasPermission',
    }),

    id() {
      return this.$route.params.planId;
    },

    createMode() {
      return this.$route.name === 'cbr.floorplans.create'
    },

    canCreate() {
      return this.hasCbrPermission('UTC');
    },

    canEdit() {
      return this.hasCbrPermission('UTU');
    },

    editMode() {
      return this.createMode ? this.canCreate : this.canEdit;
    },
  },

  methods: {
    ...mapActions({
      addAction: 'actions/addAction',
    }),

    isUnsaved() {
      const {submitSucceeded, dirty, dirtySinceLastSubmit} = this.$refs.form.formState;

      return submitSucceeded ? dirtySinceLastSubmit : dirty;
    },

    getRequestData(values) {
      return {
        name: values.name,
        description: values.description,
        virtualTour: values.virtualTourUrl
          ? {
            url: values.virtualTourUrl,
          }
          : null,
        image: values.image && {
          id: values.image,
        },
      };
    },

    async handleSubmit(values) {
      if (this.loading) {
        return;
      }

      this.loading = true;

      try {
        await this.uploadFiles();

        if (this.createMode) {
          await this.$cbrDataProvider.create('floorPlans', {
            data: this.getRequestData(values),
          });

          this.notifySuccess('floor plan created');

          setTimeout(() => {
            this.close();
          });
        } else {
          await this.$cbrDataProvider.update('floorPlans', {
            id: this.id,
            data: this.getRequestData(values),
          });

          this.notifySuccess('floor plan updated');
        }
      } catch (error) {
        this.notifyError(error.message)
      } finally {
        this.loading = false;
      }
    },

    handleDeleteFloorPlan() {
      this.loading = true;
      this.$cbrDataProvider.delete('floorPlans', {id: this.id})
        .then(() => {
          this.$refs.form.finalForm.reset();
          this.close();
          this.notifySuccess('the floor plan has been successfully deleted');
        })
        .catch(error => this.notifyError(error.message))
        .finally(() => this.loading = false);

    },
  },

  async mounted() {
    this.loading = true;

    if (!this.createMode) {
      try {
        this.initialFloorPlan = await this.$cbrDataProvider.getOne('floorPlans', {id : this.id});
        this.setActiveModalTitle(`${this.initialFloorPlan.name} floor plan`.toLowerCase());
        this.setActiveModalBreadcrumbName(this.initialFloorPlan.name.toLowerCase());

        this.initialValues = {
          name: this.initialFloorPlan.name,
          description: this.initialFloorPlan.description,
          image: this.initialFloorPlan.image.id,
          virtualTourUrl: this.initialFloorPlan.virtualTour.url,
        };
      } catch (error) {
        this.notifyError(error.message);
      }

      if (this.hasCbrPermission('FPD')) {
        this.addAction({
          routeName: this.$route.name,
          item: {
            id: 'delete-floor-plan',
            title: 'delete floor plan',
            showConfirmation: true,
            confirmationType: 'warning',
            confirmationMessage: 'are you sure you want to delete this floor plan?',
            confirmBtnText: 'delete',
            cancelBtnText: 'cancel',
          },
        });
      }
    }

    EventBus.on('confirm-delete-floor-plan', this.handleDeleteFloorPlan);

    this.loading = false;
    this.initialized = true;
  },

  beforeUnmount() {
    EventBus.off('confirm-delete-floor-plan', this.handleDeleteFloorPlan);
  }
}
</script>

<style scoped>
.grid:deep(.form-col) {
  @apply ml-0 mr-0 mb-6;
}

.grid:deep(.form-col:last-child) {
  @apply mb-0;
}

.grid:deep(textarea) {
  height: 153px;
}

.file-manager.withImage {
  @apply flex justify-center border p-3;
}

.file-manager:deep(.grid.grid-cols-2) {
  @apply grid-cols-1;
}

.unit-types {
  font-size: 1rem;
}
</style>
