<template>
  <img v-if="file" :src="src" :alt="file.original_name"/>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: {
    id: {
      type: String,
      required: true,
    },

    thumb: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      getLibraryFileById: 'files/getLibraryFileById',
    }),

    file() {
      return this.getLibraryFileById(this.id);
    },

    src() {
      return this.thumb
        ? this.file.thumbnail_url
        : this.file.image_url;
    },
  },
};
</script>
