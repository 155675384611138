export const BUSINESS_PURPOSE_GROUP_LIVING = 'LIVING';
export const BUSINESS_PURPOSE_GROUP_COMMUNITY = 'COMMUNITY';
export const BUSINESS_PURPOSE_GROUP_MAINTENANCE = 'MAINTENANCE';

export const BUSINESS_PURPOSE_UNIT = 'UNIT';
export const BUSINESS_PURPOSE_UNIT_AREA = 'UNIT_AREA';
export const BUSINESS_PURPOSE_SHARED_AREA = 'SHARED_AREA';
export const BUSINESS_PURPOSE_STAFF_ONLY = 'STAFF_ONLY';
export const BUSINESS_PURPOSE_COMMON_AREA = 'COMMON_AREA';
export const BUSINESS_PURPOSE_PARKING = 'PARKING';

export const DEFAULT_BUSINESS_PURPOSE_OPTIONS = [
    {
        key: null,
        value: 'Unspecified',
    },
    {
        $isSeparator: true,
    },
    {
        key: BUSINESS_PURPOSE_GROUP_LIVING,
        value: 'Living spaces',
        $isDisabled: true,
    },
    {
        key: BUSINESS_PURPOSE_UNIT,
        value: 'Unit',
    },
    {
        key: BUSINESS_PURPOSE_UNIT_AREA,
        value: 'Unit area',
    },
    {
        key: BUSINESS_PURPOSE_SHARED_AREA,
        value: 'Shared area',
    },
    {
        $isSeparator: true,
    },
    {
        key: BUSINESS_PURPOSE_GROUP_MAINTENANCE,
        value: 'Maintenance spaces',
        $isDisabled: true,
    },
    {
        key: BUSINESS_PURPOSE_STAFF_ONLY,
        value: 'Staff only',
    },
    {
        $isSeparator: true,
    },
    {
        key: BUSINESS_PURPOSE_GROUP_COMMUNITY,
        value: 'Community spaces',
        $isDisabled: true,
    },
    {
        key: BUSINESS_PURPOSE_COMMON_AREA,
        value: 'Common area',
    },
    {
        key: BUSINESS_PURPOSE_PARKING,
        value: 'Parking',
    },
];
