<template>
  <div class="location-panel">
    <div class="location-panel__filters" :class="css.filtersClass">
      <slot name="filters-before" />

      <div class="location-panel__search">
        <LocationSearch v-model="searchQuery" />
      </div>

      <LocationTreeModes
        v-if="showTreeModes"
        class="location-panel__mode"
        :modes="treeModes"
        v-model="treeMode"
      />
    </div>

    <LocationTree
      v-if="rootLocation && !loading"
      class="location-panel__location-tree"
      :class="css.treeClass"
      :root-id="rootLocation.id"
      :location-id="rootLocation.id"
      :mode="treeMode"
      :expand-all="eagerMode"
      @select="handleSelect"
      @add-child="$emit('add-child-location')"
      @delete="$emit('delete-location')"
      @check="handleCheckLocations"
      :checked-locations="checkedIds"
      :locations="mappedLocationTree"
      v-bind="{
        selectedId,
        searchQuery,
        withAddChild,
        withDelete,
        creatingLocation,
        checkboxSelection,
        checkboxActions,
        disabledCheckboxes,
        selectable,
        expandCallback,
        renderRoot,
        hasFilters,
      }"
    />

    <loader :loading="loading" backdrop />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import {
  ROOT_LOCATION_ID as ROOT_ID,
  TREE_MODE_L1,
} from '@/utils/constants/location-tree';
import NotifyMixin from '@/mixins/NotifyMixin';
import Loader from '@/components/ui/Loader';
import LocationTree from '@/components/ui/location-tree/LocationTree';
import LocationSearch from '@/components/ui/location-tree/LocationSearch';
import LocationTreeModes from '@/components/ui/location-tree/LocationTreeModes';

const { mapGetters, mapActions } = createNamespacedHelpers('location_tree');

const css = {
  filtersClass: 'p-4',
  treeClass: '',
};

export default {
  components: { Loader, LocationTree, LocationSearch, LocationTreeModes },

  mixins: [NotifyMixin],

  props: {
    /**
     * Data provider to fetch locations
     */
    dataProvider: {
      type: Object,
      default: () => this.$cbrDataProvider,
    },

    /**
     * Name of a resource used by the data provider
     */
    resource: {
      type: String,
      default: 'locations',
    },

    additionalRequestParams: {
      type: Boolean,
      default: ()=> ({}),
    },

    treeModes: {
      type: Array,
      required: false,
    },

    businessPurpose: {
      type: String,
      required: false,
    },

    forceTreeMode: {
      type: String,
      required: false,
    },

    showTreeModes: {
      type: Boolean,
      default: false,
    },

    eagerMode: {
      type: Boolean,
      default: false,
    },

    preselectedId: {
      type: String,
      required: false,
    },

    withAddChild: {
      type: Boolean,
      default: false,
    },

    withDelete: {
      type: Boolean,
      default: false,
    },

    creatingLocation: {
      type: Boolean,
      default: false,
    },

    checkboxSelection: {
      type: [Boolean, Array, Function],
      default: false,
    },

    checkboxActions: {
      type: Boolean,
      default: false,
    },

    disabledCheckboxes: {
      type: [Boolean, Array, Function],
      default: false,
    },

    /**
     * Array of checkbox selected locations
     */
    checkedLocations: {
      type: Array,
      required: false,
    },

    /**
     * Allow select tree node
     */
    selectable: {
      type: Boolean,
      default: true,
    },

    /**
     * Set xpanding level
     */
    expandLevel: {
      type: Number,
      required: false,
    },

    /**
     * Custom CSS styles
     */
    css: {
      type: Object,
      default() {
        return css;
      },
    },

    /**
     * Render root node
     */
    renderRoot: {
      type: Boolean,
      default: false
    },

    /**
     * Helper function for mapping location tree data to be used in `LocationTree` component
     */
    locationMapper: {
      type: Function,
      required: false,
    },
  },

  emits: [
    /**
     * Emitted on location panel click
     */
    'select-location',
    /**
     * Emitted on child location adding
     */
    'add-child-location',
    /**
     * Emitted on location delete
     */
    'delete-location',
    /**
     * Emitted on location checkbox value change
     */
    'check-locations',
  ],

  data() {
    return {
      selectedId: null,
      checkedIds: [],
      treeMode: TREE_MODE_L1,
      searchQuery: '',
      loading: false,
    };
  },

  computed: {
    ...mapGetters(['locationTree']),

    queryParams() {
      const params = {...this.additionalRequestParams};

      if (this.businessPurpose) {
        params.businessPurpose = this.businessPurpose;
      }

      if (this.searchQuery) {
        params.q = this.searchQuery;
        params['q.type'] = this.treeMode;
      }

      return params;
    },

    selectedLocation() {
      return this.selectedId && this.locationTree[this.selectedId];
    },

    rootLocation() {
      return this.locationTree[ROOT_ID];
    },

    hasFilters() {
      return !!(
        this.searchQuery &&
        !this.loading
      );
    },

    mappedLocationTree() {
      if(!this.locationMapper) {
        return this.locationTree;
      }

      const result = {};

      Object.entries(this.locationTree).map(([key, value]) => {
        result[key] = this.locationMapper(value, this.treeMode);
      });

      return result;
    },
  },

  watch: {
    selectedId(id) {
      this.$emit('select-location', id && this.locationTree[id]);
    },

    searchQuery(val) {
      val ? this.searchLocations() : this.fetchLocations();
    },

    treeMode() {
      if (this.searchQuery) {
        this.searchLocations();
      }
    },
  },

  methods: {
    ...mapActions(['addCommunityLocations', 'clearCommunityLocations']),

    handleSelect(id) {
      this.selectedId = id;
      this.$emit('select-location', id && this.locationTree[id]);
    },

    handleCheckLocations({ value, checked }) {
      this.checkedIds = checked
        ? Array.from(new Set([...this.checkedIds, ...value]))
        : this.checkedIds.filter((id) => !value.includes(id));

      this.$emit('check-locations', this.checkedIds);
    },

    async expandCallback(parentId) {
      const childLocations = await this.dataProvider.getList(this.resource, {
        parentId,
        ...this.queryParams,
      });

      this.addCommunityLocations(
        childLocations.map((location) => ({
          ...location,
          parentId,
        }))
      );
    },

    async searchLocations() {
      this.loading = true;

      // to avoid unnecessary highlighting
      this.clearCommunityLocations();

      try {
        const pendingRequestSearchQuery = this.searchQuery;
        const locations = await this.dataProvider.getList(
          this.resource,
          this.queryParams
        );

        // current request is still actual (last entered by user)
        if (pendingRequestSearchQuery === this.searchQuery) {
          this.addCommunityLocations(locations);
        }
      } catch (error) {
        this.notifyError(error.message);
      } finally {
        this.loading = false;
      }
    },

    async fetchLocations() {
      this.clearCommunityLocations();

      this.loading = true;

      try {
        const rootLocations = await this.dataProvider.getList(
          this.resource,
          this.queryParams
        );

        this.addCommunityLocations(rootLocations);
      } catch (e) {
        this.notifyError(e.message);
      } finally {
        this.loading = false;
      }
    },
  },

  async mounted() {
    if (this.forceTreeMode) {
      this.treeMode = this.forceTreeMode;
    }

    await this.fetchLocations();

    if (this.checkedLocations) {
      this.checkedIds = this.checkedLocations;
    }

    if (this.preselectedId) {
      this.handleSelect(this.preselectedId);
    }
  },
};
</script>

<style scoped>
.location-panel {
  @apply flex flex-col text-xs;
}

.location-item:deep(.highlighter .highlighted) {
  @apply text-gray-700;
}

.location-panel__location-tree {
  @apply px-4 pb-4 overflow-y-auto;
}

.location-panel__search {
  @apply flex-grow;
}

.location-panel__mode {
  @apply mt-4;
}
</style>
