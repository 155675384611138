<template>
  <div class="h-full">
    <div class="h-full grid grid-cols-3 col-gap-6">
      <div v-if="createMode" class="font-frank p-4 bg-blue-200">
        <div class="font-medium text-lg mb-4">unit type details</div>
        <div class="text-sm text-blue-800">
          Fill in required fields. <br>
          After creating unit type you can assign units
        </div>
      </div>
      <AssignedUnitsPanel
          v-else-if="!createMode && unitType"
          :total-units="unitType.totalNumberOfUnits"
          :unit-type-id="unitType.id"
          :locations="unitType.locations"
      />

      <FinalForm
          v-if="initialized"
          class="h-full col-span-2 overflow-auto"
          :submit="handleFormSubmit"
          ref="form"
          :initial-values="unitType || initialValues"
          @change.self="handleFormChange"
      >
        <template v-slot="props">
          <form
              class="grid grid-cols-10 gap-4"
              @submit="props.handleSubmit"
              ref="formElement"
          >
            <div class="form-row col-span-5">
              <TextField
                  name="name"
                  label="unit type name*"
                  :validate="[
                      required,
                      maxLength(75),
                  ]"
                  :edit-mode="editMode"
              />
            </div>
            <div class="col-span-5 row-span-2 flex flex-col items-center">
              <template v-if="formValues.floorPlan">
                <ManagedImage
                    v-if="formValues.floorPlan.image"
                    class="h-40"
                    :id="formValues.floorPlan.image.id"
                    thumb
                />
                <a
                    v-if="formValues.floorPlan.virtualTour.url"
                    :href="formValues.floorPlan.virtualTour.url"
                    target="_blank"
                    class="text-link mt-2"
                >virtual tour</a>
              </template>
              <div v-else class="floor-plan-placeholder">floor plan image</div>
            </div>
            <div class="form-row col-span-5">
              <SelectInput
                  v-if="floorPlans.length > 0"
                  name="floorPlan"
                  label="floor plan*"
                  :options="floorPlans"
                  :validate="required"
                  object-mode
                  :edit-mode="editMode"
              />
              <div v-else class="form-col">
                <div class="label">
                  floor plan*
                </div>
                <div class="flex items-center mb-2">
                  <ExclamationCircleIcon class="w-3 h-3 mr-2"/>
                  <span class="font-frank text-sm">no floor plans have been created yet</span>
                </div>
                <router-link
                    v-if="hasCbrPermission('FPC')"
                    :to="{name: 'cbr.floorplans.create'}"
                    class="text-link"
                >
                  create floor plan
                </router-link>
                <FieldError
                  name="floorPlan"
                  :validate="required"
                />
              </div>
            </div>
            <div class="form-row col-span-10">
              <TextField
                  name="description"
                  label="marketing description*"
                  :validate="[
                          required,
                          minLength(5),
                          maxLength(500),
                      ]"
                  multiline
                  :edit-mode="editMode"
              />
            </div>
            <div class="form-row col-span-2">
              <TextField
                  name="bedroomNum"
                  label="bedrooms*"
                  :validate="required"
                  :edit-mode="editMode"
              />
            </div>
            <div class="form-row col-span-2">
              <TextField
                  name="bathroomNum"
                  label="bathrooms*"
                  :validate="required"
                  :edit-mode="editMode"
              />
            </div>
            <div class="form-row col-span-4">
              <NumberRangeField
                  name="sqft"
                  label="square footage*"
                  :validate="composeValidators(numbersRange, rangeStartMin(1))"
                  :edit-mode="editMode"
                  :precision="0"
              />
            </div>
            <div class="form-row col-start-1 col-span-2">
              <AmountField
                  name="currentBaseRent"
                  label="today's base rent*"
                  :validate="[required, minValue(1)]"
                  :edit-mode="editMode"
                  :compute-width="false"
                  text-class="text-left"
                  prefix="$"
              />
            </div>
            <div class="form-row col-span-2">
              <AmountField
                  name="proFormRent"
                  label="pro forma rent"
                  :edit-mode="editMode"
                  :compute-width="false"
                  text-class="text-left"
                  prefix="$"
              />
            </div>
            <template v-if="unitType">
              <div class="form-row col-span-2">
                <div class="form-col">
                  <label>current occupancy</label>
                  <div>
                    {{ unitType.currentOccupancy }}/{{ unitType.totalNumberOfUnits }}
                    <template v-if="unitType.totalNumberOfUnits">
                      ({{ Math.round(unitType.currentOccupancy / unitType.totalNumberOfUnits * 100) }}%)
                    </template>
                  </div>
                </div>
              </div>
              <div class="form-row col-span-2">
                <div class="form-col">
                  <label>average amenity</label>
                  <div>{{ unitType.avgAmenity ? formatCurrency(unitType.avgAmenity) : '0' }}</div>
                </div>
              </div>
            </template>
          </form>
        </template>
      </FinalForm>
    </div>


    <div class="modal-footer" id="modal-footer">
      <div>
        <button
            type="button"
            class="btn-primary btn-transparent"
            @click="close"
        >
          cancel
        </button>
      </div>
      <div class="flex">
        <button
            v-if="createMode && canCreate"
            class="btn-primary mr-2"
            @click="requestFormSubmit(true)"
        >
          save and assign to units
        </button>
        <button
            v-if="editMode"
            type="submit"
            class="btn-primary btn-solid"
            @click="requestFormSubmit(false)"
        >
          save
        </button>
      </div>
    </div>

    <Loader :loading="loading" backdrop/>
  </div>
</template>

<script>
import {FinalForm} from 'vue-final-form';
import ModalNavigation from "@/mixins/ModalNavigation";
import Loader from "@/components/ui/Loader";
import TextField from "@/components/form/TextField";
import SelectInput from "@/components/form/SelectInput";
import ManagedImage from "@/components/ui/ManagedImage";
import ValidatorMixin from "@/components/form/ValidatorMixin";
import ExclamationCircleIcon from "@/components/ui/icons/ExclamationCircleIcon";
import NotifyMixin from "@/mixins/NotifyMixin";
import NumberRangeField from "@/components/form/NumberRangeField";
import EventBus from "@/utils/EventBus";
import {mapActions, mapGetters} from "vuex";
import AssignedUnitsPanel from "@/components/cbr/unit_type/AssignedUnitsPanel";
import AmountField from "@/components/form/AmountField";
import {formatCurrency} from "@/utils/String";
import ModalFooterMixin from "@/components/ui/modals/ModalFooterMixin";
import FieldError from "@/components/form/FieldError";

export default {
  components: {
    FieldError,
    AmountField,
    AssignedUnitsPanel, NumberRangeField, ExclamationCircleIcon, ManagedImage, SelectInput, FinalForm, TextField, Loader},

  mixins: [ModalNavigation, ValidatorMixin, NotifyMixin, ModalFooterMixin],

  data() {
    return {
      initialized: false,
      loading: false,
      unitType: null,
      initialValues: {currentBaseRent: 0, proFormRent: 0},
      floorPlans: [],
      goToAssign: false,
      formValues: {},
    };
  },

  computed: {
    ...mapGetters({
      hasCbrPermission: 'cbr/hasPermission',
    }),

    createMode() {
      return this.$route.name === 'cbr.unittypes.create'
    },

    canCreate() {
      return this.hasCbrPermission('UTC');
    },

    canEdit() {
      return this.hasCbrPermission('UTU');
    },

    editMode() {
      return this.createMode ? this.canCreate : this.canEdit;
    },
  },

  methods: {
    ...mapActions({
      addAction: 'actions/addAction',
    }),

    formatCurrency,

    handleFormChange(state) {
      this.formValues = state.values;
    },

    requestFormSubmit(goToAssign) {
      this.goToAssign = goToAssign;
      this.$refs.formElement.requestSubmit();
    },

    handleFormSubmit(values) {
      this.loading = true;

      const data = {
        name: values.name,
        description: values.description,
        floorPlan: {
          id: values.floorPlan.id,
        },
        bedroomNum: values.bedroomNum,
        bathroomNum: values.bathroomNum,
        areaFtMin: values.sqft[0],
        areaFtMax: values.sqft[1],
        currentBaseRent: values.currentBaseRent,
        proFormRent: values.proFormRent
      };

      const promise = this.createMode ?
          this.$cbrDataProvider.create('unitTypes', {data}).then((response) => {
            this.notifySuccess('Unit type successfully created');
            if (this.goToAssign) {
              this.$router.push({name: 'cbr.unittypes.assign', query: {unitTypeId: response.id}});
            } else {
              this.close();
            }
          }) :
          this.$cbrDataProvider.update('unitTypes', {id: this.unitType.id, data}).then(() => {
            this.notifySuccess('Changes successfully saved');
          });

      promise
          .catch(error => this.notifyError(error.message))
          .finally(() => this.loading = false);
    },

    handleDeleteUnitType() {
      this.$cbrDataProvider.delete('unitTypes', {id: this.unitType.id})
          .then(() => {
            this.notifySuccess('Unit type successfully deleted');
            this.close();
          })
          .catch(error => this.notifyError(error.message));
    }
  },

  mounted() {
    this.loading = true;

    const promises = [this.$cbrDataProvider.getList('floorPlans').then(({items}) => {
      this.floorPlans = items.map(({value}) => ({...value, key: value.id, value: value.name}));
    })];
    if (!this.createMode) {
      promises.push(this.$cbrDataProvider.getOne('unitTypes', {id: this.$route.params.typeId}).then(unitType => {
        this.unitType = {
          ...unitType,
          floorPlan: {
            ...unitType.floorPlan,
            key: unitType.floorPlan.id,
            value: unitType.floorPlan.name,
          },
          sqft: [unitType.areaFtMin, unitType.areaFtMax],
        };
        this.setActiveModalBreadcrumbName(this.unitType.name);
        this.setActiveModalTitle(`${this.unitType.name} unit type`);

        if (this.hasCbrPermission('UTD')) {
          this.addAction({
            routeName: this.$route.name,
            item: {
              id: 'delete-unit-type',
              title: 'delete unit type',
              showConfirmation: true,
              confirmationType: 'warning',
              confirmationMessage: 'are you sure you want to delete this unit type?',
              confirmBtnText: 'delete',
              cancelBtnText: 'cancel',
            },
          });
        }
      }));
    }

    Promise.all(promises)
        .catch(error => this.notifyError(error.message))
        .finally(() => {
          this.loading = false;
          this.initialized = true;
        });

    EventBus.on('confirm-delete-unit-type', this.handleDeleteUnitType);
  },

  beforeUnmount() {
    EventBus.off('confirm-delete-unit-type', this.handleDeleteUnitType);
  }
};
</script>

<style scoped>
.floor-plan-placeholder {
  @apply flex items-center justify-center w-full h-full bg-blue-200 border border-blue-400;
  @apply text-xs font-frank font-medium text-blue-800;
  margin-top: 1.8125rem;
  margin-bottom: 1rem;
}
</style>
