<template>
  <div class="flex flex-col h-full overflow-hidden">
    <div class="grid grid-cols-3 gap-8 flex-grow min-h-0 h-full">
      <LocationTreePanel
          class="bg-gray-100 border h-full overflow-auto"
          :business-purpose="businessPurposeFilter"
          :force-tree-mode="TREE_MODE_L2"
          :preselected-id="preselectedId"
          @selectLocation="location => selectedLocation = location"
          :dataProvider="$cbrDataProvider"
          :location-mapper="locationMapper"
          :additionalRequestParams="{fullTree: true}"
          eager-mode
      />
      <div class="tabs col-span-2 h-full overflow-auto">
        <Tabs
            v-if="selectedLocation"
            :key="selectedLocation.id"
            ref="tabs"
            @activeIndexChange="index => activeTabIndex = index"
        >
          <Tab title="location info">
            <LocationFormL2
                ref="form"
                :location="selectedLocation"
                :editable="hasCbrPermission('LU')"
                @update="updateLocation"
            />
          </Tab>
          <Tab title="service requests" v-if="hasSreqPermission('SRV')">
            <template v-slot:default="{isActive}">
              <ServiceRequests
                  :location-id="selectedLocation.id"
                  :location-name="selectedLocation.l2Data.name"
                  :is-visible="isActive"
              />
            </template>
          </Tab>
          <Tab
              title="assigned unit"
              v-if="selectedLocation.l2Data.businessPurpose === BUSINESS_PURPOSE_PARKING && hasCbrPermission('LU')"
          >
            <template v-slot:default="{isActive}">
              <UnitAssignment
                  ref="form"
                  :location-id="selectedLocation.id"
                  :is-visible="isActive"
              />
            </template>
          </Tab>
        </Tabs>
      </div>
    </div>
    <div v-if="selectedLocation" class="flex pt-4">
      <button
          type="button"
          class="btn-primary btn-transparent mr-4"
          @click="deselectLocation"
      >
        cancel
      </button>
      <div class="flex-grow"/>
      <button
          v-if="showSaveButton"
          type="button"
          class="btn-primary btn-solid"
          @click="save"
      >
        save
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LocationTreePanel from "@/components/ui/location-tree/LocationTreePanel";
import {
  BUSINESS_PURPOSE_GROUP_COMMUNITY,
  BUSINESS_PURPOSE_GROUP_MAINTENANCE,
  BUSINESS_PURPOSE_PARKING,
} from "@/utils/constants/cbr";
import { TREE_MODE_L2 } from '@/utils/constants/location-tree';
import ModalNavigation from "@/mixins/ModalNavigation";
import LocationFormL2 from "@/components/cbr/designation/LocationFormL2";
import ServiceRequests from "@/components/cbr/locations/ServiceRequests";
import Tabs from "@/components/ui/tabs/Tabs";
import Tab from "@/components/ui/tabs/Tab";
import SreqMixin from "@/mixins/SreqMixin";
import UnitAssignment from "@/components/cbr/locations/parking_space/UnitAssignment";
import {locationMapper} from "@/utils/cbr";

const TAB_INDEX_LOCATION_INFO = 0;
const TAB_INDEX_SERVICE_REQUESTS = 1;
const TAB_INDEX_ASSIGNED_UNIT = 2;

export default {
  components: { UnitAssignment, Tab, Tabs, ServiceRequests, LocationFormL2, LocationTreePanel },

  mixins: [ModalNavigation, SreqMixin],

  data() {
    return {
      BUSINESS_PURPOSE_PARKING,
      TAB_INDEX_LOCATION_INFO,
      TREE_MODE_L2,
      businessPurposeFilter: null,
      selectedLocation: null,
      activeTabIndex: TAB_INDEX_LOCATION_INFO,
      preselectedId: null,
      cameFromServiceRequests: false,
    };
  },

  computed: {
    ...mapGetters({
      hasCbrPermission: 'cbr/hasPermission',
    }),

    showSaveButton() {
      return (this.activeTabIndex === TAB_INDEX_LOCATION_INFO || this.activeTabIndex === TAB_INDEX_ASSIGNED_UNIT) &&
          this.hasCbrPermission('LU');
    },
  },

  methods: {
    ...mapActions({
      addCommunityLocations: 'location_tree/addCommunityLocations',
    }),

    locationMapper,

    deselectLocation() {
      this.selectedLocation = null;
    },

    save()  {
      this.$refs.form.save();
    },

    updateLocation(result) {
      this.addCommunityLocations([result]);
    },
  },

  created() {
    switch (this.$route.name) {
      case 'cbr.common':
        this.businessPurposeFilter = BUSINESS_PURPOSE_GROUP_COMMUNITY;
        break;
      case 'cbr.maintenance':
        this.businessPurposeFilter = BUSINESS_PURPOSE_GROUP_MAINTENANCE;
        break;
    }

    this.unwatchSelectedLocation = this.$watch('selectedLocation', (val) => {
      if (val) {
        if (this.cameFromServiceRequests && this.preselectedId) {
          this.$nextTick(() => {
            this.$refs.tabs.openTab(TAB_INDEX_SERVICE_REQUESTS);
          });
        }
        this.unwatchSelectedLocation();
      }
    });
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.preselectedId = from.query?.locationId;
      vm.cameFromServiceRequests = from.meta.product === 'sreq';
    });
  },
}
</script>

<style scoped>
:deep(.tabs) {
  > div {
    @apply flex flex-col h-full overflow-auto;

    > :not(.tabs-wrapper) {
      @apply flex-grow overflow-auto;
    }
  }

  .tabs-wrapper {
    @apply mx-0 mb-6 border-b-2 border-gray-200;
  }

  .tab-head {
    @apply font-frank font-medium text-sm m-0;
    color: #87C2EB;

    &:not(:last-child) {
      @apply mr-8;
    }

    &.active {
      @apply border-b-2 text-blue-500;
      margin-bottom: -2px;
    }
  }
}
</style>
