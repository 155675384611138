export const STATUS_TYPE_ALL = 'ALL';
export const STATUS_TYPE_RECEIVED = 'NEW_ONLY';
export const STATUS_TYPE_OPEN = 'OPENED';
export const STATUS_TYPE_RESOLVED = 'RESOLVED';

export const SORT_COLUMN_PRIORITY = 'PRIORITY';
export const SORT_COLUMN_ASSIGNED = 'ASSIGNED';
export const SORT_COLUMN_CATEGORY = 'CATEGORY';
export const SORT_COLUMN_CREATION_DATE = 'CREATION_DATE';
export const SORT_COLUMN_STATUS = 'STATUS';

export const SORT_DIR_ASC = 'ASC';
export const SORT_DIR_DESC = 'DESC';
