<template>
  <div class="flex flex-col h-full overflow-hidden">
    <FilterBlock
        :filters="[]"
        :values="filterValues"
        @change="setFilterValues"
    />
    <InfiniteTable
        :key="community.id"
        :data-provider="$cbrDataProvider"
        resource="floorPlans"
        :columns="columns"
        :filters="apiFilters"
        @click="item => $router.push({name: 'cbr.floorplans.details', params: {planId: item.id}})"
    >
      <template v-slot:column:image.id="{value}">
        <ManagedImage class="w-16 h-16 border border-gray-100 object-cover" :id="value" thumb />
      </template>
      <template v-slot:column:virtualTour.url="{value}">
        <a v-if="value" :href="value" target="_blank" @click.stop><ExternalLinkIcon class="w-3 h-3"/></a>
      </template>
      <template v-slot:column:description="{value}">
        <span class="line-clamp">{{value}}</span>
      </template>
      <template v-slot:column:unitTypes="{value}">
        <div class="overflow-visible whitespace-normal">
          <Tag
              v-for="unitType in value.slice(0, 4)"
              :key="unitType.id"
              :text="unitType.name"
              class="mr-1 mb-1 max-w-full"
          />
          <Tag v-if="value.length > 4" :text="`+${value.length - 4}`" class="mb-1"/>
        </div>
      </template>
    </InfiniteTable>
  </div>
</template>

<script>
import FilterBlock from "@/components/list/FilterBlock";
import InfiniteTable from "@/components/list/InfiniteTable";
import AuthMixin from "@/components/auth/AuthMixin";
import ManagedImage from "@/components/ui/ManagedImage";
import ModalNavigation from "@/mixins/ModalNavigation";
import ExternalLinkIcon from "@/components/ui/icons/ExternalLinkIcon";
import Tag from "@/components/ui/Tag";

const columns = [
  {
    name: 'name',
    title: 'floor plan name',
    class: 'w-3/12',
  },
  {
    name: 'image.id',
    title: 'image',
    class: 'w-1/12',
  },
  {
    name: 'virtualTour.url',
    title: 'virtual tour url',
    class: 'w-2/12',
  },
  {
    name: 'description',
    title: 'marketing description',
    class: 'w-3/12',
  },
  {
    name: 'unitTypes',
    title: 'assigned unit types',
    class: 'w-3/12',
  },
];

export default {
  components: {Tag, ExternalLinkIcon, ManagedImage, InfiniteTable, FilterBlock},

  mixins: [AuthMixin, ModalNavigation],

  props: {
    columns: {
      type: Array,
      default: () => columns,
    },
  },

  data() {
    return {
      filterValues: {},
    };
  },

  computed: {
    apiFilters() {
      const filters = {};

      if (this.filterValues.searchQuery) {
        filters.q = this.filterValues.searchQuery;
      }

      return filters;
    },
  },

  methods: {
    setFilterValues(values) {
      this.filterValues = values;
    },
  },
}
</script>

<style scoped>
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
  overflow-wrap: anywhere;
}
</style>
