<template>
  <SelectInput
    :name="name"
    :label="label"
    :options="purposeOptions"
    open-direction="bottom"
    :edit-mode="editMode"
    no-clear
    :searchable="false"
  >
    <template v-slot:tags="{ controlText, selectedOptions }">
      <div class="flex items-center">
        <template v-if="selectedOptions.length">
          <LocationIndicator
            v-if="!disabledOrSeparator(selectedOptions[0])"
            class="mr-2"
            size="large"
            :businessPurpose="selectedOptions[0].key"
          />
          <span
            :title="controlText"
            :class="disabledOrSeparator(selectedOptions[0]) ? 'ml-4' : ''"
            >{{ controlText }}</span
          >
        </template>
        <template v-else>
          <span class="w-2 h-2 inline-block rounded mr-2 bg-gray-300" />
          <span title="unspecified">Unspecified</span>
        </template>
      </div>
    </template>
    <template v-slot:option="{ option }">
      <LocationIndicator
        v-if="!disabledOrSeparator(option)"
        class="mr-2"
        :class="option.key ? 'ml-6' : 'ml-2'"
        size="large"
        :businessPurpose="option.key"
      />
      <span :class="option.$isDisabled && 'ml-2'" :title="option.value">{{
        option.value
      }}</span>
    </template>
  </SelectInput>
</template>

<script>
import SelectInput from '@/components/form/SelectInput';
import LocationIndicator from '@/components/ui/location-tree/LocationIndicator';
import {
  DEFAULT_BUSINESS_PURPOSE_OPTIONS,
} from '@/utils/constants/cbr';

export default {
  name: 'BusinessPurposeSelectInput',

  components: { SelectInput, LocationIndicator },

  props: {
    name: {
      type: String,
      default: 'businessPurpose',
    },

    label: {
      type: String,
      default: 'business purpose',
    },

    purposeOptions: {
      type: Array,
      default: () => DEFAULT_BUSINESS_PURPOSE_OPTIONS,
    },

    editMode: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    disabledOrSeparator(option) {
      return option?.$isSeparator || option?.$isDisabled;
    },
  },
};
</script>
