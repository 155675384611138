<template>
  <div class="flex flex-col h-full overflow-hidden">
    <FilterBlock
      :filters="[]"
      :values="filterValues"
      @change="setFilterValues"
    />
    <div class="flex-grow overflow-auto">
      <List
          :key="community.id"
          :columns="columns"
          :items="items"
          @rowClick="item => $router.push({name: 'cbr.living.units.details', params: {buildingId, unitId: item.id}})"
          class="units-table"
      >
        <template v-slot:column:name="{value}">
          <span v-html="highlight(value)"/>
        </template>
        <template v-slot:column:unitType="{value}">
          {{ value ? value.name : '-' }}
        </template>
        <template v-slot:column:rent="{value}">
          {{ value ? `$${value}` : '-' }}
        </template>
        <template v-slot:column:currentBaseRent="{item}">
          {{ item.unitType ? `$${item.unitType.currentBaseRent}` : '-' }}
        </template>
        <template v-slot:column:status="{value}">
          {{ value ? $t(`cbr.unit.statuses.${value}`) : '-' }}
        </template>
        <template v-slot:column:availableOn="{value}">
          <CommunityDateTimeDisplay v-if="value" :input="value" format="MM/DD/YY"/>
        </template>
        <template v-slot:column:residents="{value}">
        <span
            v-for="resident in prepareResidents(value).slice(0, 2)"
            :key="resident.id"
            class="resident-tag"
            v-html="resident.htmlString"
        />
          <span
              v-if="value && value.length > 2"
              class="resident-tag"
          >
          +{{value.length - 2}}
        </span>
        </template>
      </List>
    </div>

    <loader :loading="loading" backdrop/>
  </div>
</template>

<script>
import AuthMixin from "@/components/auth/AuthMixin";
import ModalNavigation from "@/mixins/ModalNavigation";
import CommunityDateTimeDisplay from "@/components/ui/CommunityDateTimeDisplay";
import FilterBlock from "@/components/list/FilterBlock";
import {prepareWordsModeHighlighter} from "@/utils/highlighting";
import NotifyMixin from "@/mixins/NotifyMixin";
import List from "@/components/list/List";
import Loader from "@/components/ui/Loader";
import {debounce} from "lodash-es";

const columns = [
  {
    name: 'name',
    title: 'unit',
    class: 'w-1/12',
  },
  {
    name: 'unitType',
    title: 'unit type',
    class: 'w-1/12',
  },
  {
    name: 'rent',
    title: 'rent',
    class: 'w-1/12',
  },
  {
    name: 'currentBaseRent',
    title: 'today\'s base rent',
    class: 'w-1/12',
  },
  {
    name: 'status',
    title: 'status',
    class: 'w-2/12',
  },
  {
    name: 'availableOn',
    title: 'available date',
    class: 'w-2/12',
  },
  {
    name: 'residents',
    title: 'residents',
    class: 'w-4/12',
  },
];

export default {
  mixins: [AuthMixin, ModalNavigation, NotifyMixin],

  components: {Loader, List, FilterBlock, CommunityDateTimeDisplay},

  props: {
    columns: {
      type: Array,
      default: () => columns,
    },
  },

  data() {
    return {
      loading: false,
      items: [],
      filterValues: {},
    };
  },

  computed: {
    apiFilters() {
      const filters = {};

      if (this.filterValues.searchQuery) {
        filters.query = this.filterValues.searchQuery;
      }

      return filters;
    },

    buildingId() {
      return this.$route.params.buildingId;
    },

    highlight() {
      return prepareWordsModeHighlighter(this.filterValues?.searchQuery || '');
    },
  },

  watch: {
    'apiFilters.query': debounce(function(){
      this.initialize();
    }, 500),
  },

  methods: {
    setFilterValues(values) {
      this.filterValues = values;
    },

    prepareResidents(residents) {
      if (this.filterValues.searchQuery) {
        return residents?.map(r => ({...r, htmlString: this.highlight(`${r.firstName} ${r.lastName}`)}))
            .sort((a, b) => {
              const aMatched = a.htmlString.length !== (`${a.firstName} ${a.lastName}`).length;
              const bMatched = b.htmlString.length !== (`${b.firstName} ${b.lastName}`).length;
              if (aMatched && !bMatched) {
                return -1;
              } else if (!aMatched && bMatched) {
                return 1;
              }
              return 0;
            }) || [];
      }

      return residents?.map(r => ({...r, htmlString: `${r.firstName} ${r.lastName}`})) || [];
    },

    initialize() {
      this.loading = true;
      this.$cbrDataProvider.getList('livingUnits', {buildingId: this.buildingId, ...this.apiFilters}).then(({items}) => {
        this.items = items.map(({value}) => value);
      }).catch(error => {
        this.notifyError(error.message);
      }).finally(() => {
        this.loading = false;
      });
    },
  },

  mounted() {
    this.initialize();
  }
};
</script>

<style scoped>
.resident-tag {
  @apply inline-block mr-1 px-2 py-1 bg-blue-100 text-blue-800 font-frank font-normal;
  font-size: 10px;
}
.units-table:deep(.highlighted) {
  background-color: yellow;
}
</style>
