<template>
  <div class="">
    <div class="flex items-center justify-between">
      <div class="font-frank font-bold text-sm text-black">lease history</div>
      <input type="text" class="text-sm p-3 border w-56" placeholder="search by residents, rent">
    </div>

    <InfiniteTable
        :data-provider="$cbrDataProvider"
        resource="leaseHistory"
        :columns="columns"
        :filters="apiFilters"
        @click="() => {}"
    >
      <template v-slot:column:residents="{value}">
        <span
            v-for="(resident, index) in value.slice(0, 2)"
            :key="index"
            class="resident-tag"
        >
          {{resident}}
        </span>
        <span
            v-if="value.length > 2"
            class="resident-tag"
        >
          +{{value.length - 2}}
        </span>
      </template>
      <template v-slot:column:rentPrice="{value}">
        ${{ value }}
      </template>
    </InfiniteTable>
  </div>
</template>

<script>
import InfiniteTable from "@/components/list/InfiniteTable";

const columns = [
  {
    name: 'startDate',
    title: 'lease start',
    class: 'w-3/12',
  },
  {
    name: 'expirationDate',
    title: 'expiration',
    class: 'w-3/12',
  },
  {
    name: 'residents',
    title: 'residents',
    class: 'w-4/12',
  },
  {
    name: 'rentPrice',
    title: 'rent',
    class: 'w-2/12',
  },
];

export default {
  components: {InfiniteTable},

  props: {
    columns: {
      type: Array,
      default: () => columns,
    },
  },

  data() {
    return {
      filterValues: {},
    };
  },

  computed: {
    apiFilters() {
      const filters = {};

      if (this.filterValues.searchQuery) {
        filters.q = this.filterValues.searchQuery;
      }

      return filters;
    },
  },
}
</script>

<style scoped>
.resident-tag {
  @apply inline-flex items-center mr-1 px-2 py-1 bg-blue-100 text-blue-800 font-frank font-normal;
  font-size: 10px;
}
</style>
