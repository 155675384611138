<template>
  <div class="flex h-full">
    <div class="flex flex-col bg-blue-50 w-1/3 mr-6 p-4 font-frank">
      <div class="text-xl font-medium text-black mb-4">
        select individual amenities and groups for location {{ locationNumber }}
      </div>
    </div>
    <div class="w-2/3">
      <div
        v-if="amenities.length === 0"
        class="flex flex-col items-center justify-center bg-blue-100 border border-blue-300 text-blue-700 py-6"
      >
        <div>
          <span
            class="inline-flex items-center justify-center w-4 h-4 border-2 border-blue-700 rounded-full text-xs font-frank font-600 mr-2">!</span>
          <span class="font-frank font-medium text-sm">no individual amenities and amenity groups yet</span>
        </div>
      </div>
      <AmenitySelector
        v-else
        :amenities="amenities"
        v-model="selectedAmenities"
        amenity-link="amenities.community.individual.edit"
        group-link="amenities.community.groups.edit"
        group-list-resource="locationsAssignmentsGroupItems"
        level="COMMUNITY"
      />
    </div>
    <ModalFooter
      :footer="footer"
      :primary="handleSubmit"
      :tertiary="close"
    />
    <Loader :loading="loading"/>
  </div>
</template>

<script>
import ModalNavigation from '@/mixins/ModalNavigation';
import NotifyMixin from '@/mixins/NotifyMixin';
import ModalFooter from '@/components/ui/modals/ModalFooter';
import Loader from '@/components/ui/Loader';
import AmenitySelector from '@/components/amenities/AmenitySelector';

export default {
  name: 'UnitAssignment',

  components: {
    AmenitySelector,
    ModalFooter,
    Loader,
  },

  mixins: [
    ModalNavigation,
    NotifyMixin
  ],

  data(){
    return {
      location: null,
      selectedAmenities: [],
      amenities: [],
      loading: false,
      footer: {
        primaryButton: 'save',
        tertiaryButton: 'cancel',
      },
    };
  },

  computed: {
    locationNumber() {
      return this.location?.unitNumber;
    },
    locationId() {
      return this.$route.params.unitId;
    },
  },

  methods: {
    fetchAmenities() {
      this.$amntDataProvider.getList('locationsAssignments')
        .then(amenities => this.amenities = amenities)
        .catch(error => this.notifyError(error.message));
    },
    fetchAssignments() {
      this.$amntDataProvider.getList('locationAssignmentsIds', {locationId: this.locationId})
        .then(selectedAmenities => this.selectedAmenities = selectedAmenities)
        .catch(error => this.notifyError(error.message));
    },
    fetchLocation() {
      this.$cbrDataProvider.getOne('livingUnits', {id: this.locationId})
        .then(location => this.location = location)
        .catch(error => this.notifyError(error.message));
    },
    handleSubmit() {
      if(this.loading){
        return;
      }

      this.loading = true;

      this.$amntDataProvider.update('locationAssignments', {
          locationId: this.locationId,
          data: this.selectedAmenities,
        })
        .then(() => this.notifySuccess('Location assignment updated successfully'))
        .catch(error => this.notifyError(error.message))
        .finally(() => this.loading = false);
    },
  },

  mounted() {
    this.loading = true;

    Promise.all([
      this.fetchAmenities(),
      this.fetchAssignments(),
      this.fetchLocation()
    ])
      .finally(() => this.loading = false);
  }
}
</script>
