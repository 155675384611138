<template>
  <div class="h-full">
    <FinalForm
      class="h-full"
      :submit="handleFormSubmit"
      :initial-values="initialValues"
      ref="form"
    >
      <template v-slot="props">
        <form
          class="h-full"
          @submit="props.handleSubmit"
        >
          <div class="grid grid-cols-2 gap-8">
            <div>
              <div class="form-row">
                <TextField
                  name="name"
                  label="name*"
                  :validate="required"
                />
              </div>
              <div class="form-row">
                <SelectInput
                  name="structureType"
                  label="structure type*"
                  :validate="required"
                  :data-provider="$cbrDataProvider"
                  resource="unitStructureTypes"
                  :request-params="structureTypesRequestParams"
                  option-key="id"
                  option-label="name"
                  object-mode
                />
              </div>
              <div class="form-row">
                <FinalField
                  name="unitType"
                  :validate="required"
                >
                  <template v-slot="unitTypeProps">
                    <div class="form-col">
                    <label>unit type*</label>
                    <Dropdown
                      :value="unitTypeProps.value"
                      @input="val => unitTypeProps.change(val)"
                      :data-provider="$cbrDataProvider"
                      resource="unitTypes"
                      label="name"
                      track-by="id"
                      lazy-load
                      object-mode
                    >
                      <template v-slot:item="{option, selected}">
                        <div class="grid grid-cols-3 gap-3 p-3 hover:bg-blue-50" :class="selected && 'bg-blue-100'">
                          <div class="flex items-center justify-center">
                            <ManagedImage class="h-auto" :id="option.floorPlan.image.id" thumb/>
                          </div>
                          <div class="col-span-2">
                            <div class="text-sm text-black mb-1">{{ option.name }}</div>
                            <div class="text-xs text-gray-500 whitespace-normal">{{ option.floorPlan.name }} floor plan</div>
                          </div>
                        </div>
                      </template>
                    </Dropdown>
                    <FieldError name="unitType"/>
                  </div>
                </template>
              </FinalField>
              </div>
              <div class="form-row">
                <AmountField
                  name="areaFt"
                  label="square footage"
                  :precision="0"
                  :compute-width="false"
                  :validate="minValue(1)"
                  text-class="text-left"
                />
              </div>
              <div class="form-row">
                <CheckboxInput
                  name="readyToShow"
                  value="ready to show"
                />
              </div>
            </div>
            <div>
              <div class="form-row">
                <TextField
                  name="description"
                  label="description*"
                  multiline
                  rows="5"
                  :validate="required"
                />
              </div>
            </div>
          </div>
          <ModalFooter
            :footer="footer"
            :tertiary="close"
          />
        </form>
      </template>
    </FinalForm>
    <Loader :loading="loading" backdrop/>
  </div>
</template>

<script>
import {FinalField, FinalForm} from 'vue-final-form';
import {isEqual} from 'lodash-es';
import ModalNavigation from '@/mixins/ModalNavigation';
import NotifyMixin from '@/mixins/NotifyMixin';
import ValidatorMixin from '@/components/form/ValidatorMixin';
import Loader from '@/components/ui/Loader';
import ModalFooter from '@/components/ui/modals/ModalFooter';
import TextField from '@/components/form/TextField';
import SelectInput from "@/components/form/SelectInput";
import CheckboxInput from "@/components/form/CheckboxInput";
import ManagedImage from "@/components/ui/ManagedImage";
import Dropdown from "@/components/ui/Dropdown";
import AmountField from "@/components/form/AmountField";
import FieldError from "@/components/form/FieldError";

export default {
  mixins: [
    ModalNavigation,
    NotifyMixin,
    ValidatorMixin
  ],

  components: {
    FieldError,
    AmountField,
    Dropdown,
    ManagedImage,
    ModalFooter,
    TextField,
    SelectInput,
    CheckboxInput,
    FinalForm,
    FinalField,
    Loader,
  },

  data() {
    return {
      loading: false,
      initialValues: {},
      footer: {
        primaryButton: 'submit',
        tertiaryButton: 'cancel',
      },
    };
  },

  computed: {
    buildingId() {
      return this.$route.params.buildingId;
    },

    structureTypesRequestParams() {
      return {
        buildingId: this.buildingId,
      };
    },

    formValues() {
      return this.$refs.form.formState.values;
    },
  },

  methods: {
    async handleFormSubmit({structureType, unitType, areaFt, ...values}) {
      const data = {
        ...values,
        structureType: structureType && {
          id: structureType.id,
        },
        unitType: unitType && {
          id: unitType.id,
        },
        areaFt: Number(areaFt),
      };

      try {
        this.loading = true;

        await this.$cbrDataProvider.create('livingUnits', {buildingId: this.buildingId, data});

        this.notifySuccess('Unit successfully saved');
        this.close();
      } catch (error) {
        this.notifyError(error.message);
      } finally {
        this.loading = false;
      }
    },
  },

  mounted() {
    this.$watch(
      () => this.formValues.unitType,
      (unitType, oldUnitType) => {
        if (!unitType || isEqual(unitType, oldUnitType)) {
          return;
        }

        this.$refs.form.finalForm.change('areaFt', unitType.areaFtMin);
      },
    );
  },
};
</script>
