<template>
  <div class="flex flex-col h-full overflow-hidden">
    <FilterBlock
      :filters="[]"
      :values="filterValues"
      @change="setFilterValues"
    />
    <InfiniteTable
      :key="community.id"
      :data-provider="$cbrDataProvider"
      resource="unitTypes"
      :columns="columns"
      :filters="apiFilters"
      @click="item => $router.push({name: 'cbr.unittypes.details', params: {typeId: item.id}})"
      class="unit-types-table"
    >
      <template v-slot:column:floorPlan.image.id="{value}">
        <ManagedImage v-if="value" class="w-16 h-16 border border-gray-100" :id="value" thumb />
      </template>
      <template v-slot:column:sqft="{item}">
        <div class="flex flex-col">
          <template v-if="item.areaFtMin === item.areaFtMax">
            {{ item.areaFtMin }}
          </template>
          <template v-else>
            {{ item.areaFtMin }} - {{ item.areaFtMax }}
          </template>
        </div>
      </template>
      <template v-slot:column:currentBaseRent="{value}">
        {{ value ? formatCurrency(value) : '-'}}
      </template>
      <template v-slot:column:currentOccupancy="{item}">
        <div class="flex flex-col">
          <div>
            {{ item.currentOccupancy ?? '-' }} / {{ item.totalNumberOfUnits ?? '-'}}
          </div>
          <div class="flex items-center">
            <ProgressBar :progress="getPercents(item.currentOccupancy, item.totalNumberOfUnits)" class="flex-grow progress-bar"/>
            <div class="text-graphite-700 ml-1">{{ getPercents(item.currentOccupancy, item.totalNumberOfUnits) }}%</div>
          </div>
        </div>
      </template>
    </InfiniteTable>
  </div>
</template>

<script>
import AuthMixin from "@/components/auth/AuthMixin";
import ModalNavigation from "@/mixins/ModalNavigation";
import InfiniteTable from "@/components/list/InfiniteTable";
import FilterBlock from "@/components/list/FilterBlock";
import ManagedImage from "@/components/ui/ManagedImage";
import {formatCurrency} from "@/utils/String";
import ProgressBar from "@/components/ui/ProgressBar";

const columns = [
  {
    name: 'name',
    title: 'unit type',
    class: 'w-1/12',
  },
  {
    name: 'floorPlan.name',
    title: 'floor plan name',
    class: 'w-2/12',
  },
  {
    name: 'floorPlan.image.id',
    title: 'floor plan image',
    class: 'w-2/12',
  },
  {
    name: 'sqft',
    title: 'sqft',
    class: 'w-1/12',
  },
  {
    name: 'bedroomNum',
    title: 'bedrooms',
    class: 'w-1/12',
  },
  {
    name: 'bathroomNum',
    title: 'bathrooms',
    class: 'w-1/12',
  },
  {
    name: 'currentOccupancy',
    title: 'current occupancy',
    class: 'w-2/12',
  },
  {
    name: 'currentBaseRent',
    title: 'today\'s base rent',
    class: 'w-2/12 text-right',
  },
];

export default {
  mixins: [AuthMixin, ModalNavigation],

  components: {ProgressBar, FilterBlock, InfiniteTable, ManagedImage},

  props: {
    columns: {
      type: Array,
      default: () => columns,
    },
  },

  data() {
    return {
      filterValues: {},
    };
  },

  computed: {
    apiFilters() {
      const filters = {};

      if (this.filterValues.searchQuery) {
        filters.q = this.filterValues.searchQuery;
      }

      return filters;
    },
  },

  methods: {
    getPercents(a, b) {
      if (!b) return 0;

      return Math.round((a ?? 0) / b * 100);
    },

    setFilterValues(values) {
      this.filterValues = values;
    },

    formatCurrency,
  },
};
</script>

<style scoped>
.progress-bar {
  max-width: calc(100% - 40px);
}

.unit-types-table:deep(.body) > .row {
  @apply items-start;
}
</style>
