<template>
  <FinalForm
    ref="form"
    class="h-full"
    :initialValues="initialValues"
  >
    <template v-slot="{values}">
      <form
        class="form-narrow flex flex-col justify-between min-h-full h-full"
      >
        <div class="flex-grow overflow-y-auto">
          <div class="grid grid-cols-12 gap-4 mb-1">
            <div class="col-span-5">
              <div class="form-row">
                <TextField
                  name="name"
                  label="amenity group name"
                  :edit-mode="false"
                />
              </div>
              <div class="form-row">
                <SelectInput
                  name="chargeCodeId"
                  label="charge code"
                  :options="chargeCodesOptions"
                  :edit-mode="false"
                />
              </div>
            </div>
            <div class="col-span-7">
              <TextField
                name="description"
                label="description"
                rows="6"
                multiline
                :edit-mode="false"
              />
            </div>
          </div>
          <div class="form-row mt-6">
            <div class="form-col">
              <div class="flex items-baseline justify-between">
                <label class="w-2/3">individual amenities</label>
              </div>
              <list
                :columns="columns"
                :items="items"
                trackBy="amenityId"
                :css="{ tableClass: 'table table--striped', bodyRowClass: 'row' }"
                resourceLabel="amenities"
              >
                <template v-slot:column:pricingUnit="{ value }">
                  {{ getPricingUnitLabel(value) || "-" }}
                </template>

                <template v-slot:column:amount="{ item, index }">
                  <AmenityAmountField
                    :key="index"
                    :rowData="item"
                    :rowIndex="index"
                    :edit-mode="false"
                  />
                </template>

                <template v-slot:column:price="{ item , index }">
                  <AmenityPriceField
                    :key="index"
                    :rowData="item"
                    :rowIndex="index"
                  />
                </template>
              </list>
            </div>
          </div>
        </div>
        <TotalPanel
          :edit-mode="false"
          level="LOCATION"
          :is-group="true"
          :values="values"
        />
      </form>
      <loader :loading="loading" :backdrop="true"/>
    </template>
  </FinalForm>
</template>

<script>
import {mapGetters} from 'vuex';
import {FinalForm} from 'vue-final-form';
import ValidatorMixin from '@/components/form/ValidatorMixin';
import ModalNavigation from "@/mixins/ModalNavigation";
import NotifyMixin from '@/mixins/NotifyMixin';
import ActionsMixin from '@/mixins/ActionsMixin';
import AmenityMixin from "@/mixins/AmenityMixin";
import InitializeFormMixin from '@/components/form/InitializeFormMixin';
import TextField from '@/components/form/TextField';
import SelectInput from '@/components/form/SelectInput';
import TotalPanel from '@/components/amenities/TotalPanel';
import Loader from '@/components/ui/Loader';
import List from '@/components/list/List';
import AmenityAmountField from "@/components/amenities/list/fields/AmenityAmountField";
import AmenityPriceField from "@/components/amenities/list/fields/AmenityPriceField";

export default {
  name: 'LivingUnitAmenityGroupDetails',

  components: {
    TotalPanel,
    SelectInput,
    TextField,
    Loader,
    FinalForm,
    List,
    AmenityAmountField,
    AmenityPriceField,
  },

  mixins: [
    NotifyMixin,
    ValidatorMixin,
    ModalNavigation,
    InitializeFormMixin,
    ActionsMixin,
    AmenityMixin,
  ],

  data() {
    return {
      loading: true,
      initialValues: {},
      chargeCodesOptions: [],
      columns: [
        {
          name: 'name',
          title: 'name',
          class: 'w-5/12',
        },
        {
          name: 'pricingUnit',
          title: 'per',
          class: 'w-1/12',
        },
        {
          name: 'amount',
          title: 'qty',
          class: 'w-3/12',
        },
        {
          name: 'price',
          title: 'price w/o discount',
          class: 'w-3/12 text-right',
        },
      ],
      items: [],
    };
  },

  computed: {
    ...mapGetters({
      hasPermission: 'amnt/hasPermission',
    }),
    locationId(){
      return this.$route.params.unitId;
    },
    amenityId() {
      return this.$route.params.amenityId;
    },
    finalForm() {
      return this.$refs.form?.finalForm;
    },
  },

  methods: {
    fetchAmenity(){
      this.$amntDataProvider.getOne('locationAssignments', {
        locationId: this.locationId,
        locationAmenityId: this.amenityId,
        type: 'groups',
      })
      .then(({
          communityGroup: {
            chargeCode,
            discount,
            discountType,
            total,
            group: {
              name,
              description,
              price: globalPrice,
              discount: globalDiscount,
              discountType: globalDiscountType,
              items,
            },
          },
        }) => {

        if(chargeCode){
          this.chargeCodesOptions = [{
            key: chargeCode.id,
            value: chargeCode.description,
          }];
        }

        const groupAmenities = items.map(({
          amount,
          amenity: {id: amenityId, name, pricingUnit, price}
        }) => ({
          amenityId,
          name,
          pricingUnit,
          amount,
          price,
        }));

        this.initialValues = {
          name,
          description,
          chargeCodeId: chargeCode?.id || null,
          globalPrice,
          globalDiscount,
          globalDiscountType,
          discount,
          discountType,
          total,
          groupAmenities,
        };

        this.setActiveModalName(name);
      })
      .catch(error => this.notifyError(error.message))
      .finally(() => this.loading = false);
    },

    unassignGroupFromUnit() {
      this.$amntDataProvider.delete('locationAssignments', {
        locationId: this.locationId,
        locationAmenityId: this.amenityId,
        type: 'groups',
      })
      .then(() => {
        this.notifySuccess('Item was successfully unassigned');
        this.close();
      })
      .catch(error => this.notifyError(error.message));
    }
  },

  mounted() {
    this.fetchAmenity();

    if(this.hasPermission('UL_M')){
      this.addConfirmAction(
        {
          id: 'amnt-unassign-amenity-group',
          title: 'unassign from unit',
          showConfirmation: true,
          confirmationMessage: 'are you sure you want to unassign this item?',
          confirmBtnText: 'yes, unassign',
          cancelBtnText: 'no, go back',
          confirmationType: 'warning'
        },
        this.unassignGroupFromUnit
      );
    }

    this.formUnsubscribe = this.finalForm.subscribe((formState) => {
      this.items = formState.values.groupAmenities;
    }, {values: true});
  },

  beforeUnmount(){
    this.formUnsubscribe();
  }
}
</script>
