<template>
  <div class="text-black">
    <div class="mb-6" v-if="openRequests.length > 0">
      <div class="flex items-center justify-between mb-4">
        <div class="text-sm font-frank font-bold">open service requests</div>
        <router-link
            v-if="openRequests.length >= 10"
            :to="{name: 'sreq.index', query: {locationId, locationName, status: STATUS_TYPE_OPEN}}"
            class="text-link text-2sm"
        >
          view all
        </router-link>
      </div>
      <service-request
          v-for="request in openRequests"
          :key="request.id"
          :request="request"
          :source-unit-id="locationId"
      />
    </div>

    <div v-if="resolvedRequests.length > 0">
      <div class="flex items-center justify-between mb-4">
        <div class="text-sm font-frank font-bold">resolved service requests</div>
        <router-link
            v-if="resolvedRequests.length >= 10"
            :to="{name: 'sreq.index', query: {locationId, locationName, status: STATUS_TYPE_RESOLVED}}"
            class="text-link text-2sm"
        >
          view all
        </router-link>
      </div>
      <service-request
          v-for="request in resolvedRequests"
          :key="request.id"
          :request="request"
          :source-unit-id="locationId"
      />
    </div>

    <div
        v-if="initialized && openRequests.length === 0 && resolvedRequests.length === 0"
        class="flex items-center justify-center p-10"
    >
      no service requests found
    </div>

    <loader :loading="loading" backdrop/>
  </div>
</template>

<script>
import NotifyMixin from "@/mixins/NotifyMixin";
import Loader from "@/components/ui/Loader";
import ServiceRequest from "@/components/cbr/locations/ServiceRequest";
import {STATUS_TYPE_OPEN, STATUS_TYPE_RESOLVED} from "@/views/sreq/list/constants";

export default {
  components: {ServiceRequest, Loader},

  mixins: [NotifyMixin],

  props: {
    locationId: {
      type: String,
      required: true,
    },

    locationName: {
      type: String,
      required: true,
    },

    isVisible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      STATUS_TYPE_OPEN,
      STATUS_TYPE_RESOLVED,
      initialized: false,
      loading: false,
      openRequests: [],
      resolvedRequests: [],
    };
  },

  watch: {
    isVisible: {
      handler: function (isVisible) {
        if (!this.initialized && isVisible) {
          this.initialize();
        }
      },
      immediate: true,
    }
  },

  methods: {
    initialize() {
      this.loading = true;

      Promise.all([
        this.$sreqDataProvider
            .getList('serviceRequests', {locationId: this.locationId, statuses: STATUS_TYPE_OPEN, limit: 10})
            .then(({items}) => {
              this.openRequests = items.map(({value}) => value);
            }),
        this.$sreqDataProvider
            .getList('serviceRequests', {locationId: this.locationId, statuses: STATUS_TYPE_RESOLVED, limit: 10})
            .then(({items}) => {
              this.resolvedRequests = items.map(({value}) => value);
            })
      ])
          .then(() => {
            this.initialized = true;
          })
          .catch(error => {
            this.notifyError(error.message);
          })
          .finally(() => {
            this.loading = false;
          });
    },
  },
}
</script>
