<template>
  <div v-if="rangeMode" class="flex items-center">
    <amount-input
      amount-class="form-input"
      ref="rangeMin"
      :value="value[0]"
      :precision="precision"
      :compute-width="false"
      @input="$emit('input', [$event, value[1]])"
      @focus="handleFocus"
      @blur="handleBlur"
    />
    <span class="font-bold text-gray-400 text-sm mx-2">-</span>
    <amount-input
      amount-class="form-input"
      ref="rangeMax"
      :value="value[1]"
      :precision="precision"
      :compute-width="false"
      @input="$emit('input', [value[0], $event])"
      @focus="handleFocus"
      @blur="handleBlur"
    />
  </div>
  <div v-else>
    <amount-input
      amount-class="form-input"
      :value="value[0]"
      :precision="precision"
      :compute-width="false"
      @input="$emit('input', [$event, $event])"
      @focus="handleFocus"
      @blur="handleBlur"
    />
  </div>
</template>

<script>
import AmountInput from "@/components/ui/AmountInput";

export default {
  name: "NumberRangeInput",

  components: {AmountInput},

  props: {
    value: {
      type: Array,
      default: () => ['', ''],
    },

    rangeMode: {
      type: Boolean,
      default: true,
    },

    /**
     * Amount precision
     */
    precision: {
      type: Number,
      default: 2,
    },
  },

  emits: ['input', 'focus', 'blur'],

  data() {
    return {
      isFocused: false,
    };
  },

  watch: {
    rangeMode(val) {
      const [min, max] = this.value;
      if (!val && min !== max) {
        this.$emit('input', [min, min]);
      }
    }
  },

  methods: {
    handleFocus() {
      if (!this.isFocused) {
        this.isFocused = true;
        this.$emit('focus');
      }
    },

    handleBlur() {
      setTimeout(() => {
        switch (document.activeElement) {
          case this.$refs?.rangeMin?.$refs?.field:
            break;
          case this.$refs?.rangeMax?.$refs?.field:
            break;
          default:
            if (this.isFocused) {
              this.isFocused = false;
              this.$emit('blur');
            }
        }
      });
    },
  },
}
</script>
